<template>
  <div class="ab-select" v-if="hasUsers">
    <v-select
        v-model="inputVal"
        :reduce="(title) => title.value"
        :options="options"
        label="title"
        :menu-props="{ top: true, offsetY: true }"
        :calculate-position="withPopper"
        append-to-body
    >
      <template slot="option" slot-scope="option">
        <div class="asset-filter__uploader">
          <div class="grid-x align-middle">
            <div class="cell shrink">
              <div
                  v-if="option.avatar"
                  class="user-avatar"
                  :style="userAvatar(option.avatar.url)"
              ></div>
              <div class="share-avatar" v-else>
                <i class="icon-menu-profile"></i>
              </div>
            </div>
            <div class="cell auto">
              {{ option.title }}
            </div>
          </div>
        </div>
      </template>
    </v-select>
    <label>{{ label }}</label>
  </div>
  <div class="ab-select" v-else>
    <v-select
        v-model="inputVal"
        :reduce="(title) => title.value"
        :options="options"
        :filterable="false"
        :clearable="false"
        :searchable="false"
        label="title"
        :calculate-position="withPopper"
    >
      <template slot="option" slot-scope="option">
        {{ option.title }}
      </template>
    </v-select>
    <label>{{ label }}</label>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { createPopper } from '@popperjs/core'

export default {
  name: 'AbSelect',
  props: {
    label: String,
    value: [String, Number],
    options: {
      Array
    },
    hasUsers: Boolean,
    placement: {
      type: String,
      default: 'bottom'
    }
  },
  computed: {
    inputVal: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    userAvatar (avatar) {
      return 'background-image:url(\'' + avatar + '\');'
    },
    withPopper (dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      dropdownList.style.width = width

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1]
            }
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn ({ state }) {
              component.$el.classList.toggle(
                'drop-up', state.placement === 'top'
              )
            }
          }
        ]
      })

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy()
    }
  },
  components: {
    vSelect
  }
}
</script>

<style lang="scss">
@import "~vue-select/src/scss/vue-select.scss";

input[readonly] {
  background-color: transparent;
}

.ab-select {
  border-radius: $form-radius;
  height: 50px;
  border: 2px solid $ui-line-color;
  padding: 14px 20px 13px;
  font-size: 14px;
  color: $dark-gray;
  position: relative;
  word-break: break-word;
  cursor: pointer;
  //min-width: 250px;
  .v-select {
    &::after {
      @include transition(transform);
      font-family: $abrnd;
      content: $icon-chevron-down;
      position: absolute;
      right: 0;
      top: 45%;
      transform: translateY(-50%);
    }

    > div {
      cursor: pointer;
    }
  }

  input {
    color: $dark-gray;
  }

  label {
    position: absolute;
    left: 15px;
    font-size: 12px;
    color: $light-gray;
    top: -11px;
    background: $white;
    padding: 0 5px;
  }

  .vs {
    &--open {
      &::after {
        transform: translateY(-45%) rotate(180deg);
      }
    }

    &__actions {
      display: none;
    }

    &__selected {
      padding: 0 10px 0 0 !important;
      border: none;
      border-radius: 0;
      line-height: inherit;
      align-items: inherit;
    }

    &__selected-options {
      padding: 0 !important;
    }

    &__dropdown-option--highlight {
      background: $light-gray-bg !important;
    }

    &__dropdown-menu {
      border-radius: 5px;
      box-shadow: 0 3px 3px rgba(#000000, 0.1);
      background: $white;
      padding: 10px !important;
      border: none;
      z-index: 999999;
    }
  }

  li {
    display: block;
    font-size: 14px;
    padding: 5px 20px;
    border-radius: 5px;
    color: $gray;
    cursor: pointer;
    position: relative;
  }
}

.vs__dropdown-menu {
  border-radius: 5px;
  box-shadow: 0 3px 3px rgba(#000000, 0.1);
  background: $white;
  padding: 10px !important;
  border: none;
  z-index: 999999;
}

.v-dialog:not(.v-dialog--fullscreen) {
  //max-height: unset;
}

</style>
