<template>
  <div
    class="ab-wysiwyg"
    :class="[
      icon ? 'ab-textarea--icon' : '',
      error ? 'has-error' : '',
      !error ? 'is-valid' : '',
      error && errorMessage ? 'has-message' : ''
    ]"
  >
    <i v-if="icon" :class="icon"></i>
    <span v-if="!error && !errorMessage" class="ab-textarea__error-message">{{
      errorMessage
    }}</span>
    <div class="ab-wysiwyg-editor">
      <froala :tag="'textarea'" :config="config" v-model="inputVal"></froala>
      <label>{{ label }}</label>
    </div>
  </div>
</template>

<script>
import VueFroala from 'vue-froala-wysiwyg'
import Vue from 'vue'

require('froala-editor/js/plugins/align.min.js')
require('froala-editor/js/plugins/inline_class.min.js')
require('froala-editor/js/plugins/lists.min.js')
require('froala-editor/js/plugins/paragraph_format.min.js')
require('froala-editor/js/plugins/image.min.js')

VueFroala.attribution = false
Vue.use(VueFroala)

export default {
  name: 'AbWysiwyg',
  props: {
    label: String,
    value: String,
    required: Boolean,
    icon: String,
    readonly: Boolean,
    error: Boolean,
    errorMessage: String,
    contentItem: Object,
    dragStatus: Boolean
  },
  data () {
    return {
      config: {
        toolbarButtons: [
          'bold',
          'italic',
          'underline',
          'alignLeft',
          'alignCenter',
          'alignRight',
          'insertLink',
          'formatUL',
          'formatOL',
          'paragraphFormat'
        ],
        pastePlain: true,
        listAdvancedTypes: false,
        quickInsertEnabled: false,
        linkStyles: {
          'button--primary': 'Button primary',
          'button--primary-hollow': 'Button primary hollow',
          'button--secondary': 'Button secondary',
          'button--secondary-hollow': 'Button secondary hollow'
        },
        linkList: [],
        inlineClasses: {
          'color--primary': 'Color primary',
          'color--secondary': 'Color secondary',
          'color--white': 'Color white',
          'color--black': 'Color black',
          'weight--light': 'Light weight',
          'weight--medium': 'Medium weight',
          'weight--bold': 'Bold weight'
        },
        paragraphFormat: {
          N: 'Paragraph',
          H1: 'Heading 1',
          H2: 'Heading 2',
          H3: 'Heading 3'
        }
      }
    }
  },
  computed: {
    inputVal: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "~froala-editor/css/froala_editor.pkgd.css";
@import "~froala-editor/css/froala_style.min.css";

.ab-wysiwyg {
  position: relative;
  background: $white;
  margin-bottom: 30px;
  .ab-wysiwyg-editor {
    background-image: none !important;
    border-radius: $form-radius;
    border: 2px solid $ui-line-color;
    padding: 14px 20px 13px;
    font-size: 14px;
    color: $dark-gray;
    background-color: transparent;
    width: 100%;
    display: block;
    min-height: 150px;
    resize: none;
    &:hover {
      border-color: $gray;
    }
  }
  &--icon {
    i {
      position: absolute;
      top: 17px;
      left: 20px;
    }
  }
  label {
    position: absolute;
    left: 15px;
    font-size: 12px;
    color: $light-gray;
    top: -10px;
    background: $white;
    padding: 0 5px;
  }
  &__error-message {
    position: absolute;
    bottom: -15px;
    font-size: 10px;
    color: $error;
    left: 20px;
    line-height: 10px;
  }
  &.has-error {
    &::after {
      position: absolute;
      font-family: $abrnd;
      color: $error;
      content: $icon-input-error;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
    }
    textarea {
      border-color: $error;
    }
    label {
      color: $error;
    }
  }
}
</style>

<style lang="scss" type="text/scss" scoped>
.wrapper {
  position: relative;
  border-radius: $form-radius;
}
.overlay {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
}
</style>
