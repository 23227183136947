<template>
  <li class="sidebar-left__item">
    <router-link
      :to="{
        name: routeName
      }"
      class="sidebar-left__item"
    >
      <img v-if="image" :src="image"/>
      <i v-else-if="icon" class="icon" :class="icon"></i>
      <div>
        <span>{{ text }}</span>
      </div>
    </router-link>
  </li>
</template>

<script>
export default {
  name: 'PageSidebarLink',
  props: {
    icon: String,
    image: String,
    text: String,
    routeName: String
  }
}
</script>

<style scoped>
img {
  max-width: 32px;
}
</style>
