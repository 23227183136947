<template>
  <router-link
    :class="cssClass"
    :to="{ name: routeName, params: { pathName: routeParam } }"
    ><span v-if="text">{{ text }}</span> <i v-if="icon" :class="icon"
  /></router-link>
</template>

<script>
export default {
  name: 'RouterButton',
  props: {
    routeName: String,
    routeParam: String,
    icon: String,
    cssClass: String,
    text: String
  }
}
</script>

<style scoped>
.ab-button--white {
  margin-left: 10px;
}
</style>
