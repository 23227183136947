var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasUsers)?_c('div',{staticClass:"ab-tags",class:[
    _vm.error ? 'has-error' : '',
    _vm.error && _vm.errorMessage ? 'has-message' : '',
    _vm.customClass ? _vm.customClass : ''
  ]},[_c('vue-tags-input',{attrs:{"tags":_vm.value,"add-on-key":[13, ','],"autocomplete-items":_vm.filteredTags,"placeholder":"","max-tags":_vm.maxTags,"add-only-from-autocomplete":_vm.addOnlyFromAutocomplete,"validation":_vm.validation,"delete-on-backspace":false},on:{"tags-changed":_vm.tagsChanged},scopedSlots:_vm._u([{key:"autocomplete-item",fn:function(props){return _c('div',{on:{"click":function($event){return props.performAdd(props.item)}}},[_c('div',{staticClass:"grid-x grid-margin-x align-middle"},[_c('div',{staticClass:"cell shrink"},[(props.item.avatar)?_c('div',{staticClass:"share-avatar",style:(_vm.userAvatar(props.item.avatar.url))}):_c('div',{staticClass:"share-avatar"},[_c('i',{staticClass:"icon-menu-profile"})])]),_c('div',{staticClass:"cell auto ml-0"},[_vm._v(" "+_vm._s(props.item.name)+" "+_vm._s(props.item.surname)+" ")])])])}}],null,false,1091006170),model:{value:(_vm.tagInput),callback:function ($$v) {_vm.tagInput=$$v},expression:"tagInput"}}),_c('label',[_vm._v(_vm._s(_vm.label))]),(_vm.error && _vm.errorMessage)?_c('span',{staticClass:"ab-tags__error-message"},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e()],1):_c('div',{staticClass:"ab-tags",class:[
    _vm.error ? 'has-error' : '',
    _vm.error && _vm.errorMessage ? 'has-message' : '',
    _vm.customClass ? _vm.customClass : ''
  ]},[_c('vue-tags-input',{attrs:{"tags":_vm.value,"add-on-key":[13, ','],"autocomplete-items":_vm.filteredTags,"placeholder":"","max-tags":_vm.maxTags,"add-only-from-autocomplete":_vm.addOnlyFromAutocomplete,"autocomplete-always-open":_vm.showAutoComplete},on:{"tags-changed":_vm.tagsChanged,"focus":_vm.onFocus,"blur":_vm.onBlur},model:{value:(_vm.tagInput),callback:function ($$v) {_vm.tagInput=$$v},expression:"tagInput"}}),_c('label',[_vm._v(_vm._s(_vm.label))]),(_vm.error && _vm.errorMessage)?_c('span',{staticClass:"ab-tags__error-message"},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }