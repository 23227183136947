<template>
  <div class="loader__container">
    <div class="loader triangle">
      <svg viewBox="0 0 86 80">
        <polygon points="43 8 79 72 7 72"></polygon>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AbLoader',
  props: {},
  computed: {
    inputVal: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.loader__container {
  transition: 300ms opacity;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 50;
  background: rgba($white, 0.5);
  left: 0;
  top: 0;
  &.fade-out {
    opacity: 0;
  }
}

.loader {
  width: 48px;
  height: 44px;
  position: absolute;
  display: inline-block;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%) scale(0.6);
  svg {
    display: block;
    width: 100%;
    height: 100%;
    polygon {
      fill: none;
      stroke: $gray;
      stroke-width: 10px;
      stroke-linejoin: round;
      stroke-linecap: round;
      stroke-dasharray: 145 76 145 76;
      stroke-dashoffset: 0;
      animation: pathTriangle 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
    }
  }
}

.loader__sidebar {
  width: 48px;
  height: 44px;
  position: absolute;
  display: inline-block;
  left: 50%;
  top: 0;
  transform: translateX(-50%) scale(0.6);
  svg {
    display: block;
    width: 100%;
    height: 100%;
    polygon {
      fill: none;
      stroke: $gray;
      stroke-width: 10px;
      stroke-linejoin: round;
      stroke-linecap: round;
      stroke-dasharray: 145 76 145 76;
      stroke-dashoffset: 0;
      animation: pathTriangle 3s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
    }
  }
}

@keyframes pathTriangle {
  33% {
    stroke-dashoffset: 74;
  }
  66% {
    stroke-dashoffset: 147;
  }
  100% {
    stroke-dashoffset: 221;
  }
}
</style>
