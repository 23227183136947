<template>
  <div
    class="sidebar-left__container"
    :class="[displaySidebar ? 'is-open' : '']"
  >
    <div class="sidebar-left">
      <div class="sidebar-left__top">
        <div class="spacer--30"></div>
        <ul>
          <page-sidebar-link
            routeName="Dashboard"
            :image="faviconImage"
            icon="icon-Beeldmerk-Wit"
            :text="$t('navigation.dashboard')"
          />
          <div class="beta"><span>{{ $t("app.beta") }}</span></div>
          <page-sidebar-link
            routeName="Dashboard"
            icon="icon-interface-icon-dashboard"
            :text="$t('navigation.dashboard')"
          />
          <page-sidebar-link
            routeName="BrandList"
            icon="icon-interface-icon-brand-positioning"
            :text="$t('navigation.positioning')"
          />
          <page-sidebar-link
            routeName="BrandGuidelineList"
            icon="icon-interface-icon-brand-guidelines"
            :text="$t('navigation.guidelines')"
          />
          <page-sidebar-link
            routeName="Calendar"
            icon="icon-interface-icon-planning-check"
            :text="$t('navigation.planning')"
          />
          <page-sidebar-link
            routeName="AssetList"
            icon="icon-interface-icon-brand-asset-manager"
            :text="$t('navigation.assets')"
          />
          <li class="sidebar-left__item" v-if="sidebarHasContent">
            <div
              class="hamburger"
              :class="[displaySidebar ? 'is-active' : '']"
              @click="toggle()"
            >
              <div class="hamburger__inner">
                <div class="line line01"></div>
                <div class="line line02"></div>
                <div class="line line03"></div>
                <div class="line line04"></div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="sidebar-left__bottom">
        <ul>

          <page-sidebar-link
            routeName="MetricsOverview"
            icon="icon-menu-metrics"
            :text="$t('navigation.metrics')"
            v-if="$store.state.is_godfather"
            class="menu-bottom-icon-color"
          />

          <page-sidebar-link
            routeName="InterfaceSettingsColors"
            icon="icon-interface-icon-styling"
            :text="$t('navigation.interface_settings')"
            v-if="$store.state.is_brand_manager"
            class="menu-bottom-icon-color"
          />

          <page-sidebar-link
            routeName="SettingsCompany"
            icon="icon-menu-settings"
            :text="$t('navigation.settings')"
            v-if="$store.state.is_brand_manager"
            class="menu-bottom-icon-color"
          />

          <page-sidebar-link
            routeName="UpdatesList"
            icon="icon-notification-update"
            :text="$t('navigation.updates')"
            class="menu-bottom-icon-color"
          />

          <page-sidebar-link
            routeName="Welcome"
            icon="icon-tips"
            :text="$t('navigation.welcome')"
            class="menu-bottom-icon-color"
          />

          <li class="sidebar-left__item menu-bottom-icon-color">
            <a @click="logOut" href="#">
              <i class="icon icon-menu-logout "></i>
              <span>{{ $t("app.logout") }}</span>
            </a>
          </li>
          <li class="sidebar-left__item">
            <router-link
              :to="{
                name: 'ProfileInfo'
              }"
              class="sidebar-left__item"
            >
              <div class="sidebar-left__avatar">
                <div :style="userAvatar"></div>
              </div>
              <span>{{ $t("navigation.profile") }}</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="sidebar-left__content">
      <portal-target name="sidebarContent"></portal-target>
    </div>
  </div>
</template>

<script>
import backend from '@/backend'
import { Wormhole } from 'portal-vue'
import PageSidebarLink from '@/views/common/components/sidebar/PageSidebarLink'

export default {
  name: 'PageSidebar',
  components: { PageSidebarLink },
  props: ['sidebar'],
  data () {
    return {
      menuClose: false
    }
  },
  methods: {
    logOut () {
      backend.logout()
      this.$router.push('/login')
    },
    toggle: function () {
      this.$store.state.menu_is_open = !this.$store.state.menu_is_open
    }
  },
  computed: {
    displaySidebar () {
      return this.$store.state.menu_is_open && this.sidebarHasContent
    },
    userProfileData: () => {
      return backend.state.userProfileData
    },
    userAvatar () {
      return (
        'background-image:url(\'' + this.$store.state.user.avatar.url + '\');'
      )
    },
    faviconImage () {
      const favicon = this.$store.state.interface_settings.login_screen_favicon
      if (favicon.length === 0 || favicon.favicon_formats === undefined) {
        return null
      }
      return favicon.favicon_formats.favicon16.path
    },
    sidebarHasContent () {
      return Wormhole.hasContentFor('sidebarContent') && this.$store.state.user.id
    }
  }
}
</script>

<style lang="scss" scoped>
.hamburger {
  transition: background-color 150ms ease-in-out;
  cursor: pointer;
  width: 54px;
  height: 38px;
  position: relative;
  border-radius: 100%;
  margin: 0 auto 30px;
  &__inner {
    width: 14px;
    height: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    .line {
      position: absolute;
      height: 2px;
      left: 0;
      background: $white;
      border-radius: 10px;
      transition: all cubic-bezier(0.25, 0.1, 0.28, 1.54) 0.32s;
    }
    .line01 {
      top: 0;
      width: 15px;
    }

    .line02 {
      top: 4px;
      width: 10px;
    }

    .line03 {
      top: 8px;
      width: 15px;
    }
    .line04 {
      top: 12px;
      width: 10px;
    }
  }
  &:hover {

    .line {
      background:var(--secondary-color);
    }
  }
  &.is-active {
    background:transparent;
    .line {
      background:var(--secondary-color);
    }
    .line01,
    .line04 {
      transform: rotate(45deg);
      top: 49%;
      width: 15px;
    }

    .line02,
    .line03 {
      transform: rotate(-45deg);
      top: 49%;
      width: 15px;
    }
  }
}
.beta {
  background: $black;
  padding: calc(0px) calc(0px) calc(1px) calc(8px);
  border-radius: $global-radius;
  margin: calc(-10px) calc(15px) calc(20px) calc(15px);
  align: center;
  span {
    font-size: 10px;
    color: $white;
  }
}
</style>
