<template>
  <div :class="cardClasses">
    <div :class="titleClasses" v-if="title">
      <div class="grid-x grid-margin-x">
        <div class="cell auto">
          <i v-if="icon" :class="icon"></i>{{ title }}
        </div>
        <div class="cell shrink">
          <slot name="header-right">
            <a v-if="downloadable" @click.prevent="$emit('download')">
              <i class="icon-download"></i>
            </a>
          </slot>
        </div>
      </div>
    </div>
    <div class="card__section" v-if="!noContentWrapper">
      <slot/>
    </div>
    <div v-else>
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardItem',
  props: {
    title: String,
    icon: String,
    noContentWrapper: Boolean,
    downloadable: Boolean,
    border: Boolean,
    margin: Boolean,
    primary: Boolean
  },
  methods: {},
  computed: {
    titleClasses: function () {
      const classes = ['card__title', 'text-uppercase', 'color--light-gray']
      if (this.icon) {
        classes.push('card__title--icon-left')
      }
      return classes
    },
    cardClasses: function () {
      const classes = ['card']
      if (this.border) {
        classes.push('has-border')
      }
      if (this.margin) {
        classes.push('has-margin')
      }
      if (this.primary) {
        classes.push('primary')
      }

      return classes
    }
  }
}
</script>
