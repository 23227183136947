<template>
  <div
    :class="$route.name == 'AssetList' ? 'page__asset-header' : 'page__header'"
  >
    <div v-if="$route.name == 'AssetList'">
      <div
        class="grid-x grid-padding-x align-right align-middle page__header-filter"
      >
        <div class="cell auto pl-0">
          <portal-target name="assetsFilter"></portal-target>
        </div>
        <div class="cell shrink">
          <portal-target name="pageHeaderRight"></portal-target>
        </div>
      </div>
      <div
        class="grid-x grid-padding-x align-right align-middle page__header-bottom"
      >
        <div class="cell auto pl-0">
          <portal-target name="pageHeaderLeft"></portal-target>
        </div>
      </div>
    </div>
    <div
      v-else-if="hideHeaderLeft"
      class="grid-x grid-padding-x align-right align-middle page__header-bottom"
    >
      <div class="cell auto pl-0"></div>
      <div class="">
        <portal-target name="pageHeaderRight"></portal-target>
      </div>
    </div>
    <div
      v-else
      class="grid-x grid-padding-x align-right align-middle page__header-bottom"
    >
      <div class="cell auto pl-0">
        <portal-target name="pageHeaderLeft"></portal-target>
        <breadcrumb-list/>
      </div>
      <div class="">
        <portal-target name="pageHeaderRight"></portal-target>
      </div>
    </div>
  </div>
</template>

<style scoped></style>

<script>
import BreadcrumbList from '@/views/common/components/navigation/BreadcrumbList'

export default {
  name: 'PageHeader',
  data () {
    return {
      pagesWithoutHeaderLeft: [
        'Dashboard',
        'BrandGuidelineList',
        'BrandList',
        'Calendar',
        'Metrics',
        'InterfaceSettings',
        'Company',
        'Updates',
        'Profile'
      ]
    }
  },
  computed: {
    hideHeaderLeft: function () {
      return this.pagesWithoutHeaderLeft.indexOf(this.$route.name) !== -1
    }
  },
  components: {
    BreadcrumbList
  }
}
</script>
