import backend from './backend'
import Vue from 'vue'
import moment from 'moment/moment'

const VueI18n = {
  locale: process.env.VUE_APP_I18N_LOCALE || 'en_EN',
  install () {
    backend.initialize()

    Vue.prototype.$t = (key, values = {}) => {
      return this.translate(key, values)
    }

    Vue.prototype.$i18n = this
  },
  localStorageKey () {
    return 'translations:' + this.locale
  },
  ensureTranslations () {
    const latestTranslation = localStorage.getItem(this.localStorageKey() + ':created')

    return backend
      .get(`${process.env.VUE_APP_URL}/translation/latest_update/` + this.locale)
      .then(response => {
        const latestUpdate = response.data.updated_at
        if (latestTranslation == null || latestUpdate > latestTranslation) {
          return this.getNewTranslations()
        } else {
          return true
        }
      })
  },
  getNewTranslations () {
    return backend
      .get(`${process.env.VUE_APP_URL}/translations/` + this.locale)
      .then(response => {
        const messages = new Map()
        for (let i = response.data.length - 1; i >= 0; i--) {
          messages.set(response.data[i].slug, response.data[i].translation)
        }
        const stringifiedTranslations = JSON.stringify(Array.from(messages.entries()))
        localStorage.setItem(this.localStorageKey(), stringifiedTranslations)
        localStorage.setItem(this.localStorageKey() + ':created', moment().toISOString())
        return true
      })
  },
  translate (key, variables) {
    const messages = new Map(JSON.parse(localStorage.getItem(this.localStorageKey())))
    if (messages.has(key)) {
      let string = messages.get(key)
      for (const variable in variables) {
        string = string.replace('{' + variable + '}', variables[variable])
      }
      return string
    } else {
      return key
    }
  }
}

VueI18n.install()

export default VueI18n
