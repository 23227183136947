import Vue from 'vue'
import VueRouter from 'vue-router'
import backend from '../backend'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll('[data-vue-router-controlled]')
  ).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (nearestWithMeta) {
    nearestWithMeta.meta.metaTags
      .map(tagDef => {
        const tag = document.createElement('meta')

        Object.keys(tagDef).forEach(key => {
          tag.setAttribute(key, tagDef[key])
        })

        // We use this to track which meta tags we create, so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '')

        return tag
      })
      // Add the meta tags to the document head.
      .forEach(tag => document.head.appendChild(tag))
  }

  if (!backend.state.brand_exists && to.name !== 'SignUp') {
    return next({ path: '/signup' })
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (backend.state.access_token == null) {
      // Ask the backend for a new access token
      backend
        .refreshToken()
        .then(() => {
          backend.refreshUserDetails().then(() => {
            next()
          })
        })
        .catch(function () {
          next({
            path: '/login',
            params: { nextUrl: to.fullPath }
          })
        })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
