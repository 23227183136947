<template>
  <div
      class="ab-date"
      :class="[
      icon ? 'ab-date--icon ab-date--icon-readonly' : '',
      error ? 'has-error' : '',
      !error ? 'is-valid' : '',
      error && errorMessage ? 'has-message' : ''
    ]"
  >
    <v-date-picker v-model="dateValue" :mode="mode" is-light locale="nl" :popover="{ placement: $screens({ default: placement }) }"
    />
    <label>{{ label }} <span v-if="required">*</span></label>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'AbDate',
  props: {
    label: String,
    mode: String,
    value: [Object, Date],
    required: Boolean,
    icon: String,
    error: Boolean,
    errorMessage: String,
    placement: {
      type: String,
      default: 'bottom-start'
    }
  },
  computed: {
    dateValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    dateFormat (value, format) {
      if (value == null) {
        return moment().format(format)
      }
      return moment(String(value)).format(format)
    }
  }
}
</script>

<style lang="scss">
.ab-date {
  .vc-container {
    width: 100%;
    border: none !important;
  }

  .vc-grid-container {
    padding: 0 !important;
  }

  .vc-header {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 10px !important;

    .vc-title {
      position: relative;
      top: 3px;
    }
  }

  .vc-arrows-container {
    padding-left: 5px;
    padding-right: 5px;

    > div {
      &:hover {
        border-radius: 100%;
      }
    }
  }

  .vc-h-full {
    &:hover {
      background: transparent;
    }

    .vc-day-content {
      border-radius: 100%;
      line-height: 28px;
    }
  }

  .vc-day-content {
    border-radius: 100%;
    width: 28px;
    height: 28px;
    padding: 0;
  }

  .vc-highlight {
    background-color: var(--primary-color);
    color: white;

    + span {
      color: white;
    }
  }

  .vc-highlights {
    + span {
      color: white;
    }

    .vc-day-layer {
      .vc-highlight {
        border-color: white;
      }
    }
  }

  .vc-popover-caret {
    display: none;
  }

  .vc-popover-content {
    padding: rem-calc(15);
    border: none;
    border-radius: 5px;
    box-shadow: 0 3px 3px rgba(#000000, 0.1);
    background: $white;
  }

  .vc-title {
    font-size: 0.875rem;
    font-weight: 500;
    color: $gray;
    text-transform: uppercase;
  }

  .vc-nav-container {
    .vc-grid-container {
      .vc-grid-cell {
        margin-bottom: 5px;
      }
    }

    span {
      border: 0.5px solid rgba($gray, 0.3);
      color: var(--primary-color);
      box-shadow: none;

      &:hover {
        background-color: rgba($gray, 0.3);
        box-shadow: none;
      }

      &.vc-grid-focus {
        background-color: var(--primary-color);
        color: white;
      }
    }
  }

  .in-prev-month {
    > div {
      opacity: 0.1;
    }
  }

  input {
    transition: all 150ms ease-in-out;
    background-image: none !important;
    border-radius: $form-radius;
    border: 2px solid $ui-line-color;
    height: auto;
    padding: 14px 20px 13px;
    font-size: 14px;
    color: $dark-gray;
    background-color: transparent;
    width: 100%;
    display: block;
    min-height: 50px;

    &:hover,
    &:focus {
      box-shadow: none;
      border-color: $gray;
    }
  }
}
</style>

<style scoped lang="scss">
.ab-date {
  position: relative;
  background: $white;

  label {
    position: absolute;
    left: 15px;
    font-size: 12px;
    color: $light-gray;
    top: -10px;
    background: $white;
    padding: 0 5px;
  }

  &__error-message {
    position: absolute;
    bottom: -15px;
    font-size: 10px;
    color: $error;
    left: 20px;
    line-height: 10px;
  }

  &.has-error {
    &::after {
      position: absolute;
      font-family: $abrnd;
      color: $error;
      content: $icon-input-error;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
    }

    input {
      border-color: $error;
    }

    label {
      color: $error;
    }
  }
}
</style>
