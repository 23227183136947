<template>
  <div class="add-brand-component" @click="$emit('add-item')">
    <span class="add-brand-component__button"
      ><i class="icon-plus"></i
      ><span v-if="title" class="add-brand-component__text">{{ title }}</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'AddBrandDataComponent',
  props: {
    title: String
  }
}
</script>

<style lang="scss" scoped>
.add-brand-component {
  text-align: center;
  background: $light-gray-bg;
  border: dashed 2px $ui-line-color;
  border-radius: $global-radius;
  margin: calc(10px) 0px;
  padding: 23px;
  &__button {
    transition: opacity 150ms ease-in-out;
    display: inline-block;
    margin: 0 auto;
    cursor: pointer;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
    i {
      border-radius: 100%;
      display: inline-block;
      color: $white;
      width: 35px;
      height: 35px;
      line-height: 35px;
      background: $body-font-color;
      font-size: 11px;
      &::before {
        font-size: 15px;
      }
    }
  }
  &:hover {
    border: dashed 2px $black;
    background: $button-discrete;
  }
}
</style>
