<template>
  <div class="ab-color-picker">
    <input type="text" v-model="textInputData" />
    <span
      class="ab-color-picker__color"
      :style="{ backgroundColor: '#' + colorPickerData.hex }"
    >
      <div class="color-picker">
        <chrome-picker v-model="colorPickerData"></chrome-picker>
      </div>
    </span>
    <label v-if="label">{{ label }}</label>
  </div>
</template>

<script>
import { Chrome } from 'vue-color'
export default {
  name: 'AbColorPicker',
  props: {
    type: String,
    label: String,
    value: String
  },
  computed: {
    textInputData: {
      get () {
        return this.value
      },
      set (val) {
        val = val.replace('#', '')
        this.$emit('update:value', val)
      }
    },
    colorPickerData: {
      get () {
        return {
          hex: this.value
        }
      },
      set (val) {
        val.hex = String(val.hex).replace('#', '')
        this.$emit('update:value', val.hex)
      }
    }
  },
  components: {
    'chrome-picker': Chrome
  }
}
</script>

<style scoped lang="scss">
.ab-color-picker {
  position: relative;
  background: $white;
  z-index: 1;
  &:hover {
    z-index: 5;
  }
  &::before {
    content: "#";
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    font-size: 14px;
  }
  input {
    background-image: none !important;
    border: solid 2px $ui-line-color;
    border-radius: $form-radius;
    height: auto;
    padding: 14px 20px 13px 30px;
    font-size: 14px;
    background-color: transparent;
    width: 100%;
    display: block;
    min-height: 50px;
    &:focus {
      border-color: $ui-line-color;
    }
  }
  label {
    position: absolute;
    left: 15px;
    font-size: 12px;
    color: $light-gray;
    top: -11px;
    background: $white;
    padding: 0 5px;
  }
  &__color {
    border: 2px solid $ui-line-color;
    position: absolute;
    top: 50%;
    border-radius: $form-radius;
    transform: translateY(-50%);
    right: 12px;
    width: 27px;
    height: 27px;
    &:hover {
      .color-picker {
        display: block;
      }
    }
  }
}
</style>

<style lang="scss">
.color-picker {
  border: none;
  border-radius: $form-radius;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  &:hover {
    z-index: 11;
    .vc-chrome {
      visibility: visible;
      opacity: 1;
    }
  }
  .vue-color {
    &__chrome__alpha-wrap,
    &__chrome__toggle-btn,
    &__chrome__fields-wrap,
    &__chrome__color-wrap {
      display: none !important;
    }
  }
  .vc-chrome {
    transition: all 150ms ease-in-out;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: calc(100% + 10px);
    right: 0;
    overflow: visible;
    &::before {
      $distance: -25px;
      position: absolute;
      top: $distance;
      left: $distance;
      right: $distance;
      bottom: $distance;
      content: "";
      display: block;
    }
  }
  .vc-chrome-alpha-wrap,
  .vc-chrome-fields-wrap,
  .vc-chrome-color-wrap {
    display: none;
  }
  .vc-chrome-hue-wrap {
    margin-bottom: 0;
    margin-top: rem-calc(10px);
  }
}
</style>
