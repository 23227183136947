export default {
  install (Vue, options) {
    Vue.mixin({
      // The mixin will add a destroyed() function to all components using this plugin.
      destroyed () {
        window.removeEventListener(
          'keydown',
          Vue.prototype.$handleKeypressEvent
        )
      }
    })

    // Add a global function setupKeypress used to defined the target function
    Vue.prototype.$setupKeypress = (targetKey, targetFunction) => {
      this.targetFunction = targetFunction
      this.targetKey = targetKey
      window.addEventListener('keydown', Vue.prototype.$handleKeypressEvent)
    }

    // Our custom eventlistener that will trigger on command+s or control+s
    Vue.prototype.$handleKeypressEvent = e => {
      if (
        (window.navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey) &&
        e.key === this.targetKey
      ) {
        e.preventDefault()
        this.targetFunction()
      }
    }
  }
}
