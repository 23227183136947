<template>
  <div class="grid-y medium-grid-frame">
    <div class="usage-warning" v-if="showUsageWarning">
      <div class="close-button" @click="suppressUsageWarning"><i class="icon-plus"></i></div>
      <div v-if="$store.state.is_brand_manager">{{ $t('metrics.usage_exceeded_brandmanager') }}
        <router-link to="/settings/company">{{ $t('metrics.click_to_upgrade') }}</router-link>
      </div>
      <div v-else>{{ $t('metrics.usage_exceeded_regular') }}</div>
    </div>
    <div class="page__container cell medium-auto medium-cell-block-container">
      <div class="grid-x">
        <div class="cell shrink medium-cell-block-y hide-scroll">
          <page-sidebar></page-sidebar>
        </div>
        <div class="cell auto medium-cell-block-y" id="pageContent">
          <div class="cell shrink header medium-cell-block-container">
            <page-header></page-header>
          </div>
          <div
            class="fr-view"
            :class="{ page__content: $route.name != 'BrandDetailEdit' }"
          >
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageSidebar from '@/views/common/components/sidebar/PageSidebar'
import PageHeader from '@/views/common/components/PageHeader'

export default {
  name: 'DefaultLayout',
  components: {
    PageSidebar,
    PageHeader
  },
  data: () => ({
    lastSuppressed: false
  }),
  mounted () {
    this.lastSuppressed = localStorage.getItem('suppressUsageWarning')
  },
  methods: {
    suppressUsageWarning () {
      const now = new Date().getTime()
      localStorage.setItem('suppressUsageWarning', now)
      this.lastSuppressed = now
    }
  },
  computed: {
    showUsageWarning () {
      // If the last suppressed date is more than a day ago, show the warning
      const now = new Date().getTime()
      if (this.lastSuppressed && this.lastSuppressed > now - 86400000) {
        return false
      }
      return this.usageExceeded
    },
    usageExceeded () {
      return this.$store.state.usage_exceeded
    }
  }
}
</script>

<style scoped>
.usage-warning {
  padding: 1rem;
  background-color: #CD3D3E;
  color: #fff;
}

.usage-warning a {
  color: #fff;
}

.usage-warning .close-button {
  float: right;
  transform: rotate(45deg);
  cursor: pointer;
}
</style>
