import axios from 'axios'
import store from '../store'

const backend = {
  state: {
    brand: '',
    brand_exists: false,
    access_token: null,
    refreshing: false
  },
  initialize () {
    let brand = ''
    const hostname = window.location.hostname
    if (hostname.includes('.abrnd.com')) {
      // Determine brand slug based on domain we're on.
      brand = hostname.replace('.abrnd.com', '')
    } else if (hostname.includes('localhost')) {
      // Default to "test" when working from localhost.
      brand = 'test'
    } else {
      // Make error verbose for now.
      // @TODO: verify this works in production and remove this alert.
      alert('Unable to initialize abrnd.')
    }
    this.state.brand = brand
  },
  logout () {
    this.state.access_token = null
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_URL}/logout`, {
          headers: {
            brand: this.state.brand
          },
          withCredentials: true
        })
        .then(() => {
          resolve(true)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  login (email, password) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_URL}/login`,
          {
            email: email,
            password: password
          },
          {
            headers: {
              brand: this.state.brand
            },
            withCredentials: true
          }
        )
        .then(response => {
          store.state.first_login = response.data.first_login
          this.state.access_token = response.data.access_token
          this.refreshUserDetails().then(() => {
            resolve(true)
          })
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  get (url) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          headers: {
            brand: this.state.brand,
            authorization: this.state.access_token
          }
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          // Attempt to recover by requesting a new token and retrying.
          // If this is also unsuccessful, fail.
          if (error.response.status === 401) {
            this.refreshToken()
              .then(() => {
                // This could potentially result in an endless loop if
                // the refreshToken succeeds but the new get() action fails on a 401 again.
                resolve(this.get(url))
              })
              .catch(() => {
                reject(error)
              })
          } else {
            // any other status code just reject immediately
            reject(error)
          }
        })
    })
  },
  post (url, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, data, {
          headers: {
            brand: this.state.brand,
            authorization: this.state.access_token
          }
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          // Attempt to recover by requesting a new token and retrying.
          // If this is also unsuccesful, fail.
          if (error.response.status === 401) {
            this.refreshToken()
              .then(() => {
                // This could potentially result in an endless loop if
                // the refreshToken succeeds but the new get() action fails on a 401 again.
                resolve(this.post(url, data))
              })
              .catch(() => {
                reject(error)
              })
          } else {
            // any other status code just reject immediately
            reject(error)
          }
        })
    })
  },
  put (url, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(url, data, {
          headers: {
            brand: this.state.brand,
            authorization: this.state.access_token
          }
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          // Attempt to recover by requesting a new token and retrying.
          // If this is also unsuccesful, fail.
          if (error.response.status === 401) {
            this.refreshToken()
              .then(() => {
                // This could potentially result in an endless loop if
                // the refreshToken succeeds but the new get() action fails on a 401 again.
                resolve(this.post(url, data))
              })
              .catch(() => {
                reject(error)
              })
          } else {
            // any other status code just reject immediately
            reject(error)
          }
        })
    })
  },
  delete (url, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(url, {
          headers: {
            brand: this.state.brand,
            authorization: this.state.access_token
          }
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          // Attempt to recover by requesting a new token and retrying.
          // If this is also unsuccesful, fail.
          if (error.response.status === 401) {
            this.refreshToken()
              .then(() => {
                // This could potentially result in an endless loop if
                // the refreshToken succeeds but the new get() action fails on a 401 again.
                resolve(this.delete(url, data))
              })
              .catch(() => {
                reject(error)
              })
          } else {
            // any other status code just reject immediately
            reject(error)
          }
        })
    })
  },
  refreshToken () {
    return new Promise((resolve, reject) => {
      // Are we already performing a refresh at this moment?
      // Wait for a bit and attempt refresh again.
      if (this.refreshing) {
        setTimeout(() => {
          resolve(this.refreshToken())
        }, 1000)
      } else {
        // We are now refreshing.
        this.refreshing = true
        axios
          .get(`${process.env.VUE_APP_URL}/token/refresh`, {
            withCredentials: true
          })
          .then(response => {
            if (response.data.access_token) {
              const tokenData = this.parseJwt(response.data.access_token)
              if (!tokenData.brands.includes(this.state.brand)) {
                reject(response)
              }
              this.state.access_token = response.data.access_token
              resolve(true)
            } else {
              reject(response)
            }
          })
          .catch(error => {
            // remove access token
            this.state.access_token = null
            reject(error)
          })
          .finally(() => {
            // No matter the outcome, we are done refreshing.
            this.refreshing = false
          })
      }
    })
  },
  refreshUserDetails () {
    return new Promise((resolve, reject) => {
      this.get(`${process.env.VUE_APP_URL}/me`).then(response => {
        store.state.user = response.data
        store.state.is_brand_manager = false
        store.state.is_godfather = false
        const userGroupArray = store.state.user.groups
        userGroupArray.forEach(user => {
          if (
            user.code.includes('brand-manager') ||
            user.code.includes('abrnd-godfather')
          ) {
            store.state.is_brand_manager = true
          }
          if (user.code.includes('abrnd-godfather')) {
            store.state.is_godfather = true
          }
        })
        this.getSubscriptionStatus()
        resolve(true)
      })
    })
  },
  getSubscriptionStatus () {
    this.get(`${process.env.VUE_APP_URL}/company_details/subscription`).then(response => {
      store.state.usage_exceeded = response.data.subscription_status !== 'OK'
    })
  },
  parseJwt (token) {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )

    return JSON.parse(jsonPayload)
  }
}

export default backend
