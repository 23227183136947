import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menu_is_open: true,
    user: [],
    first_login: false,
    interface_settings: null,
    general_primary_color: '',
    general_secondary_color: '',
    menu_primary_color: '',
    menu_secondary_color: '',
    button_primary_bg_color: '',
    button_primary_text_color: '',
    button_secondary_line_color: '',
    button_secondary_text_color: '',
    button_text_link_color: '',
    login_screen_bg_color: '',
    is_brand_manager: false,
    is_godfather: false,
    usage_exceeded: false
  },
  mutations: {},
  actions: {},
  modules: {}
})
