<template>
  <div>
    <div class="grid-x grid-margin-x edit-group-container">
      <div class="cell auto">
        <ab-color-picker :label="$t('app.hex')" :value.sync="hexInput"/>
      </div>
      <div class="cell shrink">
        <span class="ab-button--plain" @click="toCMYK">{{
            $t('builder.to_cmyk')
          }}</span>
      </div>
    </div>
    <div class="grid-x grid-margin-x edit-group-container">
      <div class="cell auto">
        <ab-rgb-input
          :label="$t('app.rgb')"
          :red.sync="red"
          :green.sync="green"
          :blue.sync="blue"
        />
      </div>
      <div class="cell shrink">
        <span class="ab-button--plain" @click="toCMYK">{{
            $t('builder.to_cmyk')
          }}</span>
      </div>
    </div>
    <div class="grid-x grid-margin-x edit-group-container">
      <div class="cell auto">
        <ab-cmyk-input
          :label="$t('app.cmyk')"
          :cyan.sync="cyan"
          :magenta.sync="magenta"
          :yellow.sync="yellow"
          :black.sync="black"
        />
      </div>
      <div class="cell shrink">
        <span class="ab-button--plain" @click="toRrbHex">{{
            $t('builder.to_rgb_hex')
          }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import AbRgbInput from '@/views/common/components/fields/AbRgbInput'
import AbCmykInput from '@/views/common/components/fields/AbCmykInput'
import AbColorPicker from '@/views/common/components/fields/AbColorPicker'
import axios from 'axios'

export default {
  name: 'AbColorCombo',
  props: {
    rgb: String,
    hex: String,
    cmyk: String
  },
  data () {
    return {
      red: '',
      green: '',
      blue: '',
      cyan: '',
      magenta: '',
      yellow: '',
      black: ''
    }
  },
  mounted () {
    const rgbParts = this.rgb.split(',')
    if (rgbParts.length === 3) {
      this.red = rgbParts[0]
      this.green = rgbParts[1]
      this.blue = rgbParts[2]
    }
    if (this.cmyk) {
      const cmykParts = this.cmyk.split(',')
      if (cmykParts.length === 4) {
        this.cyan = cmykParts[0]
        this.magenta = cmykParts[1]
        this.yellow = cmykParts[2]
        this.black = cmykParts[3]
      }
    }
  },
  methods: {
    toCMYK: function () {
      axios
        .get('https://www.thecolorapi.com/id?hex=' + this.hexInput)
        .then(response => {
          this.cyan = response.data.cmyk.c || '0'
          this.magenta = response.data.cmyk.m || '0'
          this.yellow = response.data.cmyk.y || '0'
          this.black = response.data.cmyk.k || '0'
        })
    },
    toRrbHex: function () {
      axios
        .get('https://www.thecolorapi.com/id?cmyk=' + this.cmyk)
        .then(response => {
          this.hexInput = response.data.hex.clean
        })
    },
    getHex (number) {
      const hex = Number(number).toString(16)
      return hex.length === 1 ? '0' + hex : hex
    }
  },
  computed: {
    hexInput: {
      get () {
        return this.hex
      },
      set (val) {
        this.$emit('update:hex', val)
      }
    },
    computedRgb: {
      get () {
        return this.red + ',' + this.green + ',' + this.blue
      }
    },
    computedCmyk: {
      get () {
        return (
          this.cyan + ',' + this.magenta + ',' + this.yellow + ',' + this.black
        )
      }
    }
  },
  watch: {
    hex: function () {
      const parts = this.hex.match(/.{1,2}/g)
      this.red = parseInt(parts[0], 16)
      this.green = parseInt(parts[1], 16)
      this.blue = parseInt(parts[2], 16)
    },
    computedRgb: function () {
      if (this.hexInput.length >= 6 || this.hexInput.length === 0) {
        this.hexInput =
          this.getHex(this.red) +
          this.getHex(this.green) +
          this.getHex(this.blue)
      }
      this.$emit('update:rgb', this.computedRgb)
    },
    computedCmyk: function () {
      this.$emit('update:cmyk', this.computedCmyk)
    }
  },
  components: {
    AbRgbInput,
    AbCmykInput,
    AbColorPicker
  }
}
</script>

<style lang="scss" scoped>
.edit-group-container {
  margin-top: 30px;

  .input-stroke {
    .ab-color-picker {
      input {
        background: $white;
      }
    }
  }
}

.ab-button--plain {
  width: 140px;
  text-align: center;
}
</style>
