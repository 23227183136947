import { render, staticRenderFns } from "./PageSidebarLink.vue?vue&type=template&id=2e41afa7&scoped=true"
import script from "./PageSidebarLink.vue?vue&type=script&lang=js"
export * from "./PageSidebarLink.vue?vue&type=script&lang=js"
import style0 from "./PageSidebarLink.vue?vue&type=style&index=0&id=2e41afa7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e41afa7",
  null
  
)

export default component.exports