<template>
  <div
    class="ab-input"
    :class="[
      error ? 'has-error' : '',
      !error ? 'is-valid' : '',
      error && errorMessage ? 'has-message' : ''
    ]"
    :data-input-prefix="inputPrefix"
  >
    <span v-if="!error && !errorMessage" class="ab-input__error-message">
      {{ errorMessage }}
    </span>
    <label>
      <input type="checkbox" :name="name" :value="value" v-model="inputVal" :id="value"/>
      <label :style="getCssVars" :for="value">{{ label }}</label>
      <span v-if="error && errorMessage" class="ab-input__error-message">{{
          errorMessage
        }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'AbCheckbox',
  props: {
    inputPrefix: String,
    label: String,
    value: [String, Boolean, Array],
    id: [String, Number],
    name: String,
    required: Boolean,
    error: Boolean,
    errorMessage: String,
    backgroundColor: String
  },
  computed: {
    getCssVars () {
      return {
        '--background-color': this.backgroundColor ? this.backgroundColor : '#fff',
        '--checkmark-color': this.backgroundColor ? '#fff' : '#000'
      }
    },
    inputVal: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ab-input {
  position: relative;
  input {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    &:checked {
      + label {
        &::after {
          opacity: 1;
        }
        &::before {
          border-color: var(--primary-color);
          background: var(--background-color);
        }
      }
    }
  }
  label {
    font-size: 14px;
    color: $dark-gray;
    padding-left: 15px;
    font-weight: 500;
    &::after {
      transition: 125ms ease-in-out all;
      position: absolute;
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      width: 13px;
      height: 13px;
      content: $icon-checkmark;
      font-family: $abrnd;
      border-radius: 100%;
      display: inline-block;
      line-height: 1;
      color: var(--checkmark-color);
      opacity: 0;
    }
    &::before {
      background: $white;
      transition: 125ms ease-in-out all;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 23px;
      height: 23px;
      content: "";
      border-radius: $form-radius;
      display: inline-block;
      line-height: 1;
      border: 2px solid $ui-line-color;
    }
  }
  &__error-message {
    position: absolute;
    bottom: -15px;
    font-size: 10px;
    color: $error;
    left: 20px;
    line-height: 10px;
  }
  &.has-error {
    &::after {
      position: absolute;
      font-family: $abrnd;
      color: $error;
      content: $icon-input-error;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
    }
    input {
      border-color: $error;
    }
    label {
      color: $error;
    }
  }
}
</style>
