<template>
  <div
    class="ab-input"
    :class="[
      icon ? 'ab-input--icon ab-input--icon-readonly' : '',
      error ? 'has-error' : '',
      !error ? 'is-valid' : '',
      inputPrefix ? 'input-has-prefix' : '',
      error && errorMessage ? 'has-message' : ''
    ]"
    :data-input-prefix="inputPrefix"
  >
    <i v-if="icon" :class="icon"></i>
    <span v-if="!error && !errorMessage" class="ab-input__error-message">{{
      errorMessage
    }}</span>
    <span class="ab-input__readonly" v-if="readonly">{{ value }}</span>
    <input
      v-else
      :type="type"
      :max="max"
      v-model="inputVal"
      :required="required"
      :autocomplete="autocomplete"
    />
    <label v-if="label">{{ label }} <span v-if="required">*</span></label>
    <span v-if="error && errorMessage" class="ab-input__error-message">{{
      errorMessage
    }}</span>
  </div>
</template>

<script>
export default {
  name: 'AbInput',
  props: {
    label: String,
    value: [String, Number],
    readonly: Boolean,
    required: Boolean,
    type: String,
    icon: String,
    error: Boolean,
    errorMessage: String,
    autocomplete: String,
    inputPrefix: String,
    max: Number
  },
  computed: {
    inputVal: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss">
.ab-date,
.ab-input,
.ab-select,
.ab-color-picker,
.ab-tags,
.ab-textarea {
  + .ab-date,
  + .ab-input,
  + .ab-select,
  + .ab-color-picker,
  + .ab-tags,
  + .ab-textarea,
  + .ab-wysiwyg,
  + .dropzone-single-file__container {
    margin-top: 30px;
  }
  + .dropzone-single-file__container {
    .dropzone-single-file {
      border-width: 2px !important;
    }
  }
}
</style>

<style scoped lang="scss">
.ab-input {
  position: relative;
  background: $white;
  &.input-has-prefix {
    input {
      padding: 14px 20px 13px 30px;
    }
    &::before {
      content: attr(data-input-prefix);
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      font-size: 14px;
      color: $dark-gray;
    }
  }
  input,
  &__readonly {
    background-image: none !important;
    border-radius: $form-radius;
    border: 2px solid $ui-line-color;
    height: auto;
    padding: 14px 20px 13px;
    font-size: 14px;
    color: $dark-gray;
    background-color: transparent;
    width: 100%;
    display: block;
    min-height: 50px;
    &:focus {
      border-color: $gray;
    }
  }
  &--icon {
    i {
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
    }
    input,
    .ab-input__readonly {
      padding-left: 45px;
    }
  }
  label {
    position: absolute;
    left: 15px;
    font-size: 12px;
    color: $light-gray;
    top: -10px;
    background: $white;
    padding: 0 5px;
  }
  &__error-message {
    position: absolute;
    bottom: -15px;
    font-size: 10px;
    color: $error;
    left: 20px;
    line-height: 10px;
  }
  &.has-error {
    &::after {
      position: absolute;
      font-family: $abrnd;
      color: $error;
      content: $icon-input-error;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
    }
    input {
      border-color: $error;
    }
    label {
      color: $error;
    }
  }
}
</style>
