<template>
  <span @click="$emit('delete')" class="ab-button--white ab-button--delete ab-button--icon"
    ><i class="icon-icon-trash"
  /></span>
</template>

<script>
export default {
  name: 'DeleteButton'
}
</script>

<style scoped></style>
