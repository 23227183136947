<template>
  <a :class="cssClass" @click.prevent="downloadFile(url)"
    >{{ title }} <i v-if="icon" class="icon-download"></i
  ></a>
</template>

<script>
import backend from '@/backend'

export default {
  name: 'DownloadButton',
  props: {
    url: String,
    title: String,
    cssClass: String,
    icon: Boolean,
    // type = pages or assets
    type: String
  },
  methods: {
    downloadFile: function (url) {
      const pageUrl = this.$route.params.pathName ? this.$route.params.pathName : 'dashboard'
      // First trigger a download activity on the backend
      backend
        .get(
          `${process.env.VUE_APP_URL}/${this.type}/${pageUrl}/download`
        )
        .then(() => {
          // Then open up the downloaded url file
          window.open(url)
        })
    }
  }
}
</script>

<style scoped></style>
