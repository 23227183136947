import { render, staticRenderFns } from "./BreadcrumbList.vue?vue&type=template&id=48043e30&scoped=true"
import script from "./BreadcrumbList.vue?vue&type=script&lang=js"
export * from "./BreadcrumbList.vue?vue&type=script&lang=js"
import style0 from "./BreadcrumbList.vue?vue&type=style&index=0&id=48043e30&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48043e30",
  null
  
)

export default component.exports