<template>
  <div>
    <div v-if="fileUpload">
      <div class="dropzone-file-list__container">
        <ul class="dropzone-file-list">
          <li
            class="dropzone-file-list__file"
            v-for="(file, index) in inputData"
            :key="index"
          >
            {{ readableFileName(file.file_name) }}
            <span v-if="actionButtonText && isResizable(fileExtension(file.file_name))" @click="triggerAction(file)" :class="actionButtonClass(file)">{{ actionButtonText }} <i class="icon-preview"></i></span>
            <delete-card
              customClass="dropzone-file-list__remove"
              v-on:confirmDelete="$emit('delete-download', index)"
            ></delete-card>
          </li>
        </ul>
        <div class="vue-dropzone-multiple">
          <vue-dropzone
            @vdropzone-success="vfileComplete"
            @vdropzone-error="vUploadFileError"
            :id="'dropzone-file' + id"
            :options="addIndexToOptions(dropzoneFileOptions, id)"
            ref="myVueDropzone"
            :useCustomSlot="true"
            @vdropzone-processing="uploadInProgress"
          >
            <div class="dropzone__multiple">
              <div class="vue-dropzone__file-drop">
                {{ $t('forms.select_multiple_files') }}
              </div>
              <div class="vue-dropzone__file-remove">
                {{ $t('forms.select_file') }}
              </div>
            </div>
          </vue-dropzone>
        </div>
      </div>
    </div>
    <div v-else-if="singleFileUpload">
      <vue-dropzone
        @vdropzone-success="vImageComplete"
        @vdropzone-error="vUploadError"
        :id="'dropzone' + id"
        :options="addIndexToOptions(dropzoneSingleFileOptions, id)"
        ref="myVueDropzone"
        @vdropzone-mounted="placeCurrentFile"
        @vdropzone-removed-file="fileRemoved"
        :useCustomSlot="true"
        :include-styling="false"
      >
        <div class="dropzone-single-file">
          {{ $t('forms.select_file') }}
        </div>
      </vue-dropzone>
    </div>
    <div v-else>
      <vue-dropzone
        @vdropzone-success="vImageComplete"
        @vdropzone-error="vUploadError"
        :id="'dropzone' + id"
        :options="addIndexToOptions(dropzoneOptions, id)"
        ref="myVueDropzone"
        @vdropzone-mounted="placeThumbnail"
        @vdropzone-removed-file="fileRemoved"
        @vdropzone-processing="uploadInProgress"
        :useCustomSlot="true"
      >
        <div class="dropzone-custom__content">
          <i class="icon-upload"></i>
        </div>
      </vue-dropzone>
    </div>
    <span v-if="error" class="ab-dropzone__error-message">{{
        errorMessage
      }}</span>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import backend from '@/backend'
import helpers from '@/helpers'

export default {
  name: 'AbDropzone',
  mixins: [helpers],
  props: {
    id: [String, Number],
    data: [Object, Array],
    fileUpload: Boolean,
    singleFileUpload: Boolean,
    type: String,
    relation: String,
    errorMessage: String,
    error: Boolean,
    actionButtonText: String,
    actionButtonActive: [Object, Array]
  },
  data () {
    return {
      isDestroying: false,
      files: [],
      actionedFile: '',
      dropzoneOptions: {
        url: `${process.env.VUE_APP_URL}/image/new`,
        headers: {
          brand: backend.state.brand,
          authorization: backend.state.access_token
        },
        maxFiles: 1,
        maxFilesize: 1024,
        timeout: 0,
        parallelUploads: 1,
        removeAllFiles: false,
        resizeQuality: 1,
        addRemoveLinks: true,
        dictRemoveFile: this.$t('app.remove_image'),
        dictCancelUpload: this.$t('app.cancel_upload'),
        dictCancelUploadConfirmation: this.$t('app.confirm_cancel_upload'),
        thumbnailHeight: null,
        thumbnailWidth: null,
        uploadMultiple: false,
        dictDefaultMessage: this.$t('app.upload_image')
      },
      dropzoneSingleFileOptions: {
        url: `${process.env.VUE_APP_URL}/image/new`,
        headers: {
          brand: backend.state.brand,
          authorization: backend.state.access_token
        },
        maxFiles: 1,
        maxFilesize: 1024,
        timeout: 0,
        parallelUploads: 1,
        previewTemplate: this.template(),
        removeAllFiles: false,
        resizeQuality: 1,
        thumbnailWidth: 0,
        addRemoveLinks: true,
        dictRemoveFile: '',
        dictCancelUpload: '',
        dictCancelUploadConfirmation: '',
        uploadMultiple: false,
        dictDefaultMessage: ''
      },
      dropzoneFileOptions: {
        url: `${process.env.VUE_APP_URL}/image/new`,
        headers: {
          brand: backend.state.brand,
          authorization: backend.state.access_token
        },
        removeAllFiles: false,
        timeout: 0,
        maxFilesize: 1024,
        resizeQuality: 1,
        addRemoveLinks: true,
        dictRemoveFile: this.$t('app.remove_image'),
        dictCancelUpload: this.$t('app.cancel_upload'),
        dictCancelUploadConfirmation: this.$t('app.confirm_cancel_upload'),
        createImageThumbnails: false,
        uploadMultiple: true,
        renameFile: true,
        dictDefaultMessage: this.$t('app.upload_image')
      }
    }
  },
  methods: {
    template: function () {
      return `
          <div class="dropzone-single-file">
            <span data-dz-name></span>
          </div>
        `
    },
    vUploadError: function (file, message, xhr) {
      let errorMessage = this.$t('app.cant_upload_chosen_image')
      if (file.name.match(/[^a-zA-Z \d.-]/g)) {
        errorMessage = this.$t('app.chosen_filename_has_special_characters')
      }
      this.$notify({
        group: 'custom-template-error',
        title: false,
        position: 'bottom left',
        text: errorMessage
      })
      // Refresh backend token in case the error was due to an expired session.
      backend.refreshToken().then(() => {
        const newHeaders = {
          brand: backend.state.brand,
          authorization: backend.state.access_token
        }
        this.$refs.myVueDropzone.setOption('headers', newHeaders)
      })
      this.$emit('upload-error')
    },
    vUploadFileError: function (file, message, xhr) {
      let errorMessage = this.$t('app.cant_upload_chosen_file')
      if (file.name.match(/[^a-zA-Z \d.-]/g)) {
        errorMessage = this.$t('app.chosen_filename_has_special_characters')
      }
      this.$notify({
        group: 'custom-template-error',
        title: false,
        position: 'bottom left',
        text: errorMessage
      })
      // Refresh backend token in case the error was due to an expired session.
      backend.refreshToken().then(() => {
        const newHeaders = {
          brand: backend.state.brand,
          authorization: backend.state.access_token
        }
        this.$refs.myVueDropzone.setOption('headers', newHeaders)
      })
      this.$emit('upload-error')
    },
    placeThumbnail: function () {
      this.$nextTick(() => {
        const file = { size: 123, name: this.inputData.image, type: 'image' }

        if (this.inputData) {
          if (this.inputData.url) {
            const url = this.inputData.url.split(' ').join('%20')
            this.$refs.myVueDropzone.manuallyAddFile(file, url)
          } else if (this.inputData.image && this.inputData.image.filepath) {
            const url = this.inputData.image.filepath.split(' ').join('%20')
            this.$refs.myVueDropzone.manuallyAddFile(file, url)
          } else if (this.inputData.image) {
            const url = this.inputData.image.split(' ').join('%20')
            this.$refs.myVueDropzone.manuallyAddFile(file, url)
          }
        }
      })
    },
    fileExtension (filename) {
      return filename.split('.').pop()
    },
    isResizable (extension) {
      const resizable = ['JPG', 'JPEG', 'PNG']
      return resizable.includes(extension.toUpperCase())
    },
    downloadableFilename (value) {
      return value.split(' ').join('%20')
    },
    placeCurrentFile: function () {
      this.$nextTick(() => {
        if (this.inputData) {
          if (this.inputData.url) {
            const file = {
              size: 123,
              name: this.readableFileName(this.inputData.url),
              type: 'image'
            }
            const url = this.downloadableFilename(this.inputData.url)
            this.$refs.myVueDropzone.manuallyAddFile(file, url)
          } else if (this.inputData.file && this.inputData.file.filepath) {
            const file = {
              size: 123,
              name: this.readableFileName(this.inputData.file.filepath),
              type: 'image'
            }
            const url = this.downloadableFilename(this.inputData.file.filepath)

            this.$refs.myVueDropzone.manuallyAddFile(file, url)
          } else if (this.inputData.file) {
            const file = {
              size: 123,
              name: this.readableFileName(this.inputData.file),
              type: 'image'
            }
            const url = this.downloadableFilename(this.inputData.file)
            this.$refs.myVueDropzone.manuallyAddFile(file, url)
          }
        }
      })
    },
    vfileComplete (file, response) {
      response.files.forEach(filePath => {
        if (this.files.indexOf(filePath) === -1) {
          this.files.push(filePath)
          const data = {
            file_name: filePath.split('/').pop(),
            filepath: filePath
          }
          this.inputData.push(data)
          this.$emit('image-added', data)
        }
      })
    },
    vImageComplete (file, response) {
      const data = {
        new_uploaded_image: true,
        filepath: response.files[0]
      }
      if (this.type === 'file') {
        this.inputData.file = data
      } else {
        this.inputData.image = data
      }
      this.$emit('image-added', data)
    },
    uploadInProgress () {
      this.$emit('upload-in-progress')
    },
    uploadError () {
      this.$emit('upload-error')
    },
    fileRemoved (file, error, xhr) {
      if (this.isDestroying) {
        // Dont set files as deleted if destroying the component
        // (destroying currently also calls the "file removed" event.)
        return
      }
      if (file && !this.fileUpload) {
        if (file.manuallyAdded === true) {
          this.inputData.deleted = true
        } else {
          this.inputData.image = null
        }
      }
      this.$emit('image-removed')
    },
    getOptions (index) {
      const options = JSON.parse(JSON.stringify(this.dropzoneOptions))
      options.params = { index: index }
      return options
    },
    addIndexToOptions (options, index) {
      options = JSON.parse(JSON.stringify(options))
      options.params = { index: index, relation: this.relation }
      return options
    },
    triggerAction (file) {
      this.actionedFile = file.filepath
      this.$emit('action-button-clicked', file)
    },
    actionButtonClass (file) {
      const classes = ['ab-button--white', 'ab-button-small', 'action-button']
      if (this.actionButtonActive !== undefined && file.filepath === this.actionButtonActive.filepath) {
        classes.push('inactive')
      }
      return classes
    }
  },
  watch: {
    data: function (val) {
      if (!this.fileUpload && !this.singleFileUpload) {
        this.isDestroying = true
        this.$refs.myVueDropzone.removeAllFiles()
        this.placeCurrentFile()
        this.placeThumbnail()
        this.isDestroying = false
      }
    }
  },
  beforeDestroy () {
    this.isDestroying = true
  },
  computed: {
    inputData: {
      get () {
        return this.data
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  components: {
    vueDropzone: vue2Dropzone
  }
}
</script>

<style lang="scss">
.action-button {
  position: absolute;
  right: 50px;

  &.inactive {
    display: none;
  }
}

.ab-dropzone {
  &__error-message {
    position: absolute;
    bottom: -15px;
    font-size: 10px;
    color: $error;
    left: 20px;
    line-height: 10px;
  }
}
</style>
