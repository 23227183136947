<template>
  <div class="wrapper has-border">
    <div class="overlay" v-if="disabled"></div>
    <froala :tag="'textarea'" :config="config" v-model="inputVal"></froala>
  </div>
</template>

<script>
import backend from '@/backend'
import VueFroala from 'vue-froala-wysiwyg'
import Vue from 'vue'

require('froala-editor/js/plugins/align.min.js')
require('froala-editor/js/plugins/inline_class.min.js')
require('froala-editor/js/plugins/lists.min.js')
require('froala-editor/js/plugins/paragraph_format.min.js')
require('froala-editor/js/plugins/image.min.js')
require('froala-editor/js/plugins/link.min.js')

Vue.use(VueFroala)

export default {
  name: 'ContentEditor',
  props: {
    contentData: String,
    disabled: Boolean
  },
  computed: {
    inputVal: {
      get () {
        return this.contentData
      },
      set (val) {
        this.$emit('update:contentData', val)
      }
    }
  },
  mounted () {
    backend.get(`${process.env.VUE_APP_URL}/pages/all`).then(response => {
      response.data.pages.forEach(category => {
        this.config.linkList.push({
          text: category.name,
          displayText: `<span class="link-parent">${category.name}</span>`
        })
        category.children.forEach(page => {
          this.config.linkList.push({
            text: page.name,
            displayText: '<span class="link-child">' + page.name + '</span>',
            href: page.url,
            target: ''
          })
        })
      })
    })
  },
  data () {
    return {
      test: '',
      show: false,
      config: {
        toolbarButtons: [
          'bold',
          'italic',
          'underline',
          'alignLeft',
          'alignCenter',
          'alignRight',
          'formatUL',
          'formatOL',
          'insertLink',
          'inlineClass',
          'paragraphFormat',
          'insertImage'
        ],
        pastePlain: true,
        requestHeaders: {
          brand: backend.state.brand,
          authorization: backend.state.access_token
        },
        imageUploadURL: `${process.env.VUE_APP_URL}/image/new?type=froala`,
        images: true,
        imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
        imageDefaultAlign: 'left',
        imageDefaultMargin: 0,
        imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif', 'webp', 'svg+xml'],
        listAdvancedTypes: false,
        quickInsertEnabled: false,
        linkStyles: {
          'button--primary': 'Button primary',
          'button--primary-hollow': 'Button primary hollow',
          'button--secondary': 'Button secondary',
          'button--secondary-hollow': 'Button secondary hollow'
        },
        linkList: [],
        inlineClasses: {
          'color--primary': 'Color primary',
          'color--secondary': 'Color secondary',
          'color--white': 'Color white',
          'color--black': 'Color black',
          'weight--light': 'Light weight',
          'weight--medium': 'Medium weight',
          'weight--bold': 'Bold weight',
          'size--hero': 'Hero'
        },
        paragraphFormat: {
          N: 'Paragraph',
          H1: 'Heading 1',
          H2: 'Heading 2',
          H3: 'Heading 3',
          H4: 'Heading 4',
          H5: 'Heading 5'
        }
      }
    }
  }
}
</script>
<style lang="scss">
@import "~froala-editor/css/froala_editor.pkgd.css";
@import "~froala-editor/css/froala_style.min.css";

.fr-toolbar {
  border: none;
  .fr-top {
    border-radius: $global-radius;
  }
  .fr-newline {
    background: $white;
  }
  .fr-command.fr-btn {
    svg path {
    }
    &:hover {
      background: rgba(0,0,0, 0.1);
    }
  }
  .fr-command.fr-btn svg path {
    fill: $gray;
  }
}
.wrapper {
  .second-toolbar {
    border: none;
  }
}
.fr-box.fr-basic .fr-wrapper {
  border: none;
  border-radius: 0 0 $global-radius $global-radius;
}
.fr-box .fr-counter {
  display: none;
}
.link-child {
  margin-left: 5px;
}
.link-parent {
  font-weight: 500;
  cursor: default;
  width: 100%
}
</style>

<style lang="scss" type="text/scss" scoped>
.wrapper {
  position: relative;
  border-radius: $form-radius;
}
.overlay {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
}
</style>
