<template>
  <div id="app">
    <div :style="appStyle">
      <div v-if="loading">
      </div>
      <div v-else>
        <router-view />

        <notifications group="custom-template" position="bottom left">
          <template slot="body" slot-scope="props">
            <div class="notification-copy">
              <div class="notification-copy__content">
                <p>
                  {{ props.item.text }}
                  <span class="close-button" @click="props.close">{{
                      $t("app.close")
                    }}</span>
                </p>
              </div>
            </div>
          </template>
        </notifications>
        <notifications group="custom-template-error" position="bottom left">
          <template slot="body" slot-scope="props">
            <div class="notification-copy bg--error">
              <div class="notification-copy__content">
                <p class="color--white">
                  {{ props.item.text }}
                  <span class="close-button" @click="props.close">{{
                      $t("app.close")
                    }}</span>
                </p>
              </div>
            </div>
          </template>
        </notifications>
        <vue-dialog />
        <modals-container />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment/moment'

export default {
  name: 'App',
  data: () => ({
    loading: true
  }),
  computed: {
    appStyle () {
      const settings = this.$store.state.interface_settings
      return {
        '--primary-color': '#' + settings.general_primary_color,
        '--secondary-color': '#' + settings.general_secondary_color,
        '--menu-color': '#' + settings.menu_primary_color,
        '--menu-secondary-color': '#' + settings.menu_secondary_color,
        '--button-primary-color': '#' + settings.button_primary_bg_color,
        '--button-primary-text-color': '#' + settings.button_primary_text_color,
        '--button-secondary-line-color':
          '#' + settings.button_secondary_line_color,
        '--button-secondary-text-color':
          '#' + settings.button_secondary_text_color,
        '--button-text-link-color': '#' + settings.button_text_link_color,
        '--login-screen-bg-color': '#' + settings.login_screen_bg_color,
        '--menu-primary-icon-color': '#' + settings.menu_primary_icon_color,
        '--menu-secondary-icon-color': '#' + settings.menu_secondary_icon_color
      }
    }
  },
  watch: {
    $route: {
      handler: function (val) {
        this.$nextTick(() => {
          if (document.getElementById('pageContent')) {
            document.getElementById('pageContent').scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            })
          }
        })
      },
      immediate: false
    }
  },
  mounted () {
    const settings = this.$store.state.interface_settings
    const faviconFormats = settings.login_screen_favicon.favicon_formats
    const viewport = document.querySelector("[name='viewport']")

    if (localStorage.getItem('locale') !== null) {
      moment.locale(localStorage.getItem('locale'))
      this.$i18n.locale = localStorage.getItem('locale')
    }

    // Check if custom favicon is set
    if (faviconFormats) {
      Array.from(document.querySelectorAll("[rel='icon']")).map(el => {
        return el.remove()
      })

      const keys = Object.keys(faviconFormats)
      keys.forEach(key => {
        const item = faviconFormats[key]
        const faviconElement = document.createElement('link')
        faviconElement.rel = 'icon'
        faviconElement.type = item.type
        faviconElement.href = item.path

        if (item.size) {
          faviconElement.sizes = item.size + 'x' + item.size

          if (item.size === '180') {
            faviconElement.rel = 'apple-touch-icon'
          }
        }

        viewport.insertAdjacentElement('afterEnd', faviconElement)
      })
    }

    this.$i18n.ensureTranslations().then(() => {
      this.loading = false
    })
  }
}
</script>

<style lang="scss">
@import "assets/scss/app";

html {
  font-size: inherit !important;
}

#modals-container {
  z-index: 998;
  position: relative;
}
</style>
