<template>
  <div class="ab-input edit-group edit-group--multiple">
    <input
      class="edit-group-field text-center"
      type="text"
      maxlength="3"
      :value="cyan"
      @input="$emit('update:cyan', $event.target.value)"
    />
    <input
      class="edit-group-field text-center"
      type="text"
      maxlength="3"
      :value="magenta"
      @input="$emit('update:magenta', $event.target.value)"
    />
    <input
      class="edit-group-field text-center"
      type="text"
      maxlength="3"
      :value="yellow"
      @input="$emit('update:yellow', $event.target.value)"
    />
    <input
      class="edit-group-field text-center"
      type="text"
      maxlength="3"
      :value="black"
      @input="$emit('update:black', $event.target.value)"
    />
    <label>{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'AbCmykInput',
  props: {
    label: String,
    cyan: [String, Number],
    magenta: [String, Number],
    yellow: [String, Number],
    black: [String, Number]
  }
}
</script>

<style lang="scss" scoped>
.edit-group {
  border: 0px;
  margin-bottom: 0px;
}

.ab-input {
  position: relative;
  background: $white;
  border: 2px solid $ui-line-color;
  &:focus-within {
    border: 2px solid $gray;
  }
  input {
    background-image: none !important;
    border: 0px;
    height: auto;
    padding: 14px 20px 13px;
    font-size: 14px;
    color: $dark-gray;
    background-color: transparent;
    width: 100%;
    display: block;
    min-height: 48px;
    &:first-child,
    &:nth-child(2),
    &:nth-child(3) {
      border-radius: 0px;
      border-right: 2px solid $ui-line-color;
    }
  }
  label {
    position: absolute;
    left: 15px;
    font-size: 12px;
    color: $light-gray;
    top: -11px;
    background: $white;
    padding: 0 5px;
  }
}
</style>
