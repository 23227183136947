<template>
  <dropdown-item ref="dropdown" :isIcon="false" :align="'left'" :y="55" :x="103">
    <template slot="btn">
      <span class="button-branded button-branded--share">
        {{ $t('contact.button') }} <i class="icon-icon-profile"></i>
      </span>
    </template>
    <template slot="body">
      <div class="shareTags dropdown-share">
        <form id="contactBrandManForm">
          <card-item>
            <span>{{ $t('contact.explanation') }}</span>
          </card-item>
          <hr/>
          <card-item>
            <h5 class="medium">{{ $t('contact.message_title') }}</h5>
            <div class="spacer--10"></div>
            <div class="event-item__field">
              <textarea
                v-model="contactMessage"
                rows="1"
                name="contactMessage"
              ></textarea>
              <label>{{ $t('contact.message') }}</label>
            </div>
            <div class="spacer--20"></div>
            <div class="text-right pb-3">
              <button
                v-if="contactMessage.length > 5"
                type="submit"
                class="color--primary"
                @click.prevent="submitContact"
              >
                {{ $t('app.send') }}
              </button>
              <span v-else class="disabled--submit">{{ $t('app.send') }}</span>
            </div>
          </card-item>
        </form>
        <ab-loader v-if="showLoader"></ab-loader>
      </div>
    </template>
  </dropdown-item>
</template>

<script>
import backend from '@/backend'

export default {
  name: 'ContactDropdown',
  data () {
    return {
      showLoader: false,
      tag: '',
      tags: [],
      autocompleteItems: [],
      validation: [
        {
          classes: 'is-invalid',
          rule: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          disableAdd: true
        }
      ],
      contactMessage: '',
      userData: false,
      currentUrl: ''
    }
  },
  mounted () {
    // Listen to the dropdown-open event and get userlist when triggered
    this.$root.$on('bp-dropdown:open', () => this.dropdownOpened())
  },
  methods: {
    deleteObject: function (index) {
      this.$delete(this.tags, index)
    },
    submitContact: function () {
      this.showLoader = true
      backend
        .post(`${process.env.VUE_APP_URL}/mail/brand_manager`, {
          pageTitle: this.pageTitle,
          contactMessage: this.contactMessage,
          shareLink: this.currentUrl
        })
        .then(() => {
          this.tags = []
          this.contactMessage = ''
          this.$root.$emit('bp-dropdown:hide')
          this.$notify({
            group: 'custom-template',
            title: false,
            position: 'bottom left',
            text: this.$t('contact.message_send')
          })
          this.showLoader = false
        })
        .catch(() => {
          this.showLoader = false
          this.$notify({
            group: 'custom-template-error',
            title: false,
            position: 'bottom left',
            text: this.$t('contact.message_error')
          })
        })
    },
    dropdownOpened: function () {
      this.setCurrentUrl()
      if (!this.userData) {
        backend.get(`${process.env.VUE_APP_URL}/me`).then(response => {
          this.userData = response.data
        })
      }
    },
    doCopy: function (value) {
      this.$copyText(value)
      this.$notify({
        group: 'custom-template',
        title: false,
        position: 'bottom left',
        text: this.$t('share.copy_url', {
          value: value
        })
      })
    },
    setCurrentUrl: function () {
      this.currentUrl =
        window.location.protocol +
        '//' +
        window.location.host +
        this.$router.currentRoute.fullPath
    }
  },
  computed: {
    pageTitle () {
      return document.title
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/init/variables/variables";

.tags-input-tag {
  font-size: 14px;
  font-weight: 500;

  &__item {
    margin-top: 30px;
  }

  i {
    color: $light-gray;
    cursor: pointer;

    &:hover {
      i {
        color: $white;
      }
    }
  }
}

.shareTags {
  .vue-tags-input .ti-input .ti-tag {
    display: none !important;
  }
}
</style>
