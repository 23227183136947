<template>
  <div class="editor-item__header">
    <div class="grid-x">
      <div class="text-left cell auto align-self-middle">
        <h3>{{ title }}</h3>
      </div>
      <div class="cell shrink minus-top">
        <span
          v-if="helperReference && hasHelper"
          class="ab-button-small ab-button--description"
          @click="openExplanation"
        >Tips<i class="icon-tips"></i> </span
        >
        <slot name="settings"></slot>
        <span class="ab-button-small ab-button--delete editor-toolbar-item" @click="show(id)"
        ><i class="icon-icon-trash"></i
        ></span>
        <span
          class="ab-button-small ab-button--cancel editor-toolbar-item"
          @click="minimizeBlock"
        ><i :class="[isVisible ? 'icon-chevron-up' : 'icon-chevron-down']"></i
        ></span>
        <span class="ab-button-small ab-button--cancel editor-toolbar-item editor-item__drag"
        ><i class="icon-drag-and-drop"></i
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'editorHeader',
  props: {
    title: String,
    items: String,
    index: String,
    item: Object,
    showSettings: Boolean,
    helperReference: Number,
    hasHelper: Boolean
  },
  data () {
    return {
      isVisible: true,
      id: null,
      hideSettings: true
    }
  },
  mounted () {
    this.id = this._uid
  },
  methods: {
    openExplanation () {
      let vm = this.$parent
      while (vm.$options.name !== 'BrandDetailEdit') {
        vm = vm.$parent
      }
      vm.openExplanation(this.helperReference)
    },
    minimizeBlock () {
      this.$parent.isVisible = !this.$parent.isVisible
      this.isVisible = !this.isVisible
    },
    show (id) {
      this.$modal.show('dialog', {
        title: '',
        text: this.$t('app.remove_item'),
        buttons: [
          {
            title: this.$t('app.cancel'),
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: this.$t('app.remove_item'),
            default: true,
            handler: () => {
              this.$parent.$emit('remove')
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    },
    toggleEditor: function () {
      this.$parent.hideSettings = !this.$parent.hideSettings
      this.hideSettings = !this.hideSettings
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/init/_init";

textarea {
  padding: 0;
  height: auto;
  resize: none;
}

.button__explanation {
  cursor: help;
  font-size: calc(14px);
  font-weight: 500;

  .bp-dropdown {
    height: calc(31px);
    top: calc(-4px);
    width: 0px;
    margin-left: calc(5px);
    padding-left: calc(10px);
    padding-right: calc(38px);
    background: #cecece;
    border-radius: calc(5px);
  }

  i {
    font-size: 15px;
    position: relative;
    top: 1px;
    color: $warning;
  }
}

.editor-toolbar-item {
  height: calc(31px);
  top: calc(10px);
  width: 0px;
  margin-left: calc(5px);
  padding-right: calc(37px);
}

.bp-dropdown {
  height: calc(31px);
  top: calc(2px);
  width: 0px;
  margin-left: calc(5px);
  padding-right: calc(32px);
  padding-left: (14px);
  border-radius: $global-radius;
  background: $save;
  color: darken($save, 40%);

  &:hover {
    background: darken($save, 20%);
    color: lighten($save, 30%);
  }
}

.minus-top {
  margin-top: calc(-10px);
  margin-right: calc(-10px);
}
</style>
