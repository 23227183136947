<template>
  <div
    class="ab-textarea"
    :class="[
      icon ? 'ab-textarea--icon' : '',
      error ? 'has-error' : '',
      !error ? 'is-valid' : '',
      error && errorMessage ? 'has-message' : ''
    ]"
  >
    <i v-if="icon" :class="icon"></i>
    <span v-if="!error && !errorMessage" class="ab-textarea__error-message">{{
      errorMessage
    }}</span>
    <span class="ab-textarea__readonly" v-if="readonly">{{ value }}</span>
    <textarea v-else v-model="inputVal" :required="required" />
    <label>{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'AbTextarea',
  props: {
    label: String,
    value: String,
    required: Boolean,
    icon: String,
    readonly: Boolean,
    error: Boolean,
    errorMessage: String
  },
  computed: {
    inputVal: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ab-textarea {
  position: relative;
  background: $white;
  textarea,
  &__readonly {
    background-image: none !important;
    border-radius: $form-radius;
    border: 2px solid $ui-line-color;
    padding: 14px 20px 13px;
    font-size: 14px;
    color: $dark-gray;
    background-color: transparent;
    width: 100%;
    display: block;
    min-height: 150px;
    resize: none;
    &:focus {
      border-color: $gray;
    }
  }
  &--icon {
    i {
      position: absolute;
      top: 17px;
      left: 20px;
    }
    textarea,
    .ab-textarea__readonly {
      padding-left: 45px;
    }
  }
  label {
    position: absolute;
    left: 15px;
    font-size: 12px;
    color: $light-gray;
    top: -10px;
    background: $white;
    padding: 0 5px;
  }
  &__error-message {
    position: absolute;
    bottom: -15px;
    font-size: 10px;
    color: $error;
    left: 20px;
    line-height: 10px;
  }
  &.has-error {
    &::after {
      position: absolute;
      font-family: $abrnd;
      color: $error;
      content: $icon-input-error;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
    }
    textarea {
      border-color: $error;
    }
    label {
      color: $error;
    }
  }
}
</style>
