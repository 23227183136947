import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import backend from './backend'

import moment from 'moment'

// Layouts
import DefaultLayout from './views/layouts/DefaultLayout'

// Buttons
import DeleteButton from './views/common/components/buttons/DeleteButton'

import SubmitButton from './views/common/components/buttons/SubmitButton'

import CancelButton from './views/common/components/buttons/CancelButton'

import RouterButton from './views/common/components/buttons/RouterButton.vue'

import DownloadButton from './views/common/components/buttons/DownloadButton.vue'

import VuePageTransition from 'vue-page-transition'

import Dropdown from 'bp-vuejs-dropdown'

import ProfileImage from './views/common/components/elements/ProfileImage'

// Fields
import AbColorPicker from './views/common/components/fields/AbColorPicker'

import AbColorCombo from './views/common/components/fields/AbColorCombo'

import AbDate from './views/common/components/fields/AbDate'

import AbDropzone from './views/common/components/fields/AbDropzone'

import AbInput from './views/common/components/fields/AbInput'

import AbTags from './views/common/components/fields/AbTags'

import AbRadio from './views/common/components/fields/AbRadio'

import AbCheckbox from './views/common/components/fields/AbCheckbox'

import AbSelect from './views/common/components/fields/AbSelect'

import AbTextarea from './views/common/components/fields/AbTextarea'

import AbWysiwyg from './views/common/components/fields/AbWysiwyg'

// Elements
import AbLoader from './views/common/components/elements/AbLoader'

import CardItem from './views/common/components/elements/CardItem'

import AddBrandDataButton from './views/brand/components/edit/elements/modal/AddBrandDataButton'

import AddBrandComponent from './views/brand/components/edit/elements/modal/AddBrandComponent'

import Notifications from 'vue-notification'

import VueClipboard from 'vue-clipboard2'

import VModal from 'vue-js-modal'

import Dialog from './views/common/components/modals/Dialog.vue'

import tipModal from './views/common/components/modals/tipModal.vue'

import DataTable from './views/common/components/tables/DataTable.vue'

import Vuelidate from 'vuelidate'

import VCalendar from 'v-calendar'

import SaveKeypress from './save-keypress.js'

import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

import EditorHeader from './views/brand/components/edit/elements/EditorHeader.vue'

import ContentEditor from './views/brand/components/edit/elements/ContentEditor.vue'

import DeleteCard from './views/brand/components/edit/elements/DeleteCard'

import draggable from 'vuedraggable'

import PortalVue from 'portal-vue'

import shareDropdown from './views/common/components/elements/ShareDropdown'

import contactDropdown from './views/common/components/elements/Contact'

import i18n from './i18n'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'

moment.locale(process.env.VUE_APP_I18N_LOCALE || 'en')
Vue.component('default-layout', DefaultLayout)
Vue.component('delete-button', DeleteButton)
Vue.component('submit-button', SubmitButton)
Vue.component('cancel-button', CancelButton)
Vue.component('router-button', RouterButton)
Vue.component('download-button', DownloadButton)
Vue.use(VuePageTransition)
Vue.component('dropdown-item', Dropdown)
Vue.component('profile-image', ProfileImage)
Vue.component('ab-color-picker', AbColorPicker)
Vue.component('ab-color-combo', AbColorCombo)
Vue.component('ab-date', AbDate)
Vue.component('ab-dropzone', AbDropzone)
Vue.component('ab-input-field', AbInput)
Vue.component('ab-tags-field', AbTags)
Vue.component('ab-radio', AbRadio)
Vue.component('ab-checkbox', AbCheckbox)
Vue.component('ab-select', AbSelect)
Vue.component('ab-textarea', AbTextarea)
Vue.component('ab-wysiwyg', AbWysiwyg)
Vue.component('ab-loader', AbLoader)
Vue.component('card-item', CardItem)
Vue.component('add-brand-data-button', AddBrandDataButton)
Vue.component('add-brand-component', AddBrandComponent)
Vue.use(Notifications)
Vue.use(VueClipboard)
Vue.use(VModal, {
  dynamic: true,
  dynamicDefaults: { clickToClose: true }
})
Vue.component('vue-dialog', Dialog)
Vue.component('tip-modal', tipModal)
Vue.component('data-table', DataTable)
Vue.use(Vuelidate)
Vue.use(VCalendar)
Vue.use(SaveKeypress)
Vue.component('calendar-list', Calendar)
Vue.component('date-picker', DatePicker)
Vue.component('editor-header', EditorHeader)
Vue.component('content-editor', ContentEditor)
Vue.component('delete-card', DeleteCard)
Vue.component('draggable-item', draggable)
Vue.use(PortalVue)
Vue.component('share-dropdown', shareDropdown)
Vue.component('contact-dropdown', contactDropdown)
Vue.config.productionTip = false

Sentry.init({
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [new VueIntegration({ Vue, attachProps: true })]
})

backend.initialize()

backend
  .get(`${process.env.VUE_APP_URL}/interface_settings`)
  .then(response => {
    store.state.interface_settings = response.data
    backend.state.brand_exists = true
  }).catch(error => {
    store.state.interface_settings = {}
    backend.state.brand_exists = false
    alert(error)
  }).finally(() => {
    new Vue({
      i18n,
      store,
      router,
      backend,
      render: h => h(App)
    }).$mount('#app')
  })
