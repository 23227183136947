<template>
  <span
    v-if="isSaving"
    class="ab-button--white"
    :class="{ 'is-saving': isSaving }"
    ><ab-loader /> {{ $t("app.save") }} <i class="icon-save"></i>
  </span>
  <span
    v-else-if="processing"
    class="ab-button--white"
    :class="{ 'is-disabled': processing }"
    >{{ $t("app.save") }} <i class="icon-save"></i>
    <span class="is-processing">{{ $t("assets.is_uploading") }}</span>
  </span>
  <label v-else for="submitForm" class="ab-button--white ab-button--save" tabindex="0"
    >{{ $t("app.save") }} <i class="icon-save"></i>
  </label>
</template>

<script>
export default {
  name: 'SubmitButton',
  props: {
    processing: Boolean,
    isSaving: Boolean
  }
}
</script>

<style lang="scss" scoped>
.ab-button--white {
  .is-processing {
    transition: 150ms all ease-in-out;
    color: $body-font-color;
    display: inline-block;
    position: absolute;
    white-space: nowrap;
    background: $white;
    box-shadow: 2px 3px 3px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 2px 10px;
    left: 50%;
    transform: translateX(-50%);
    top: calc(100% + 5px);
    font-size: 11px;
    &::before {
      width: 10px;
      height: 10px;
      content: "";
      display: inline-block;
      position: absolute;
      top: -5px;
      left: 50%;
      background: $white;
      transform: translateX(-50%) rotate(45deg);
      box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
    }
  }
  &.is-saving {
    border-color: rgba($ui-line-color, 0.5);
    background: rgba($ui-line-color, 0.5);
    cursor: default;
    color: transparent;
  }
  &.is-disabled {
    border-color: rgba($ui-line-color, 0.5);
    background: rgba($ui-line-color, 0.5);
    color: rgba($body-font-color, 0.5);
    cursor: default;
    &:hover {
      border-color: rgba($ui-line-color, 0.5);
      background: rgba($ui-line-color, 0.5);
      color: rgba($body-font-color, 0.5);
      cursor: default;
    }
  }
}
</style>
