<template>
  <div class="ab-radio">
    <input type="radio" :name="name" :value="option" :id="value" v-model="inputVal" :icon="icon"/><label
      :for="value"
      >{{ label }}</label
    >
  </div>
</template>

<script>
export default {
  name: 'AbRadio',
  props: {
    name: String,
    label: String,
    value: String,
    icon: String,
    required: Boolean,
    error: Boolean,
    errorMessage: String,
    option: String
  },
  computed: {
    inputVal: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ab-radio {
  position: relative;
  input {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    &:checked {
      + label {
        &::after {
          opacity: 1;
        }
        &::before {
          border-color: $primary-color;
        }
      }
    }
  }
  label {
    font-size: 14px;
    color: $dark-gray;
    padding-left: 25px;
    font-weight: 500;
    &::after {
      transition: 125ms ease-in-out all;
      margin-left: 5px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 13px;
      height: 13px;
      content: "";
      border-radius: 100%;
      display: inline-block;
      line-height: 1;
      background: $primary-color;
      opacity: 0;
    }

    &::before {
      background: $white;
      transition: 125ms ease-in-out all;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 23px;
      height: 23px;
      content: "";
      border-radius: 100%;
      display: inline-block;
      line-height: 1;
      border: 2px solid $ui-line-color;
    }
  }
}
</style>
