<template>
  <ul class="breadcrumbs-list">
    <li class="breadcrumbs-list__item return">
      <router-link :to="parentRoute">
        <i class="icon-return"></i>
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'BreadcrumbList',
  computed: {
    parentRoute: function () {
      const pathArray = this.$route.path.split('/')
      if (pathArray[pathArray.length - 1] === '') {
        pathArray.pop()
      }
      pathArray.pop()
      return pathArray.join('/')
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs-list__item {
  a {
    &:first-letter {
      text-transform: uppercase;
    }
  }
}
</style>
