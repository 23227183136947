<template>
  <div class="add-brand-data">
    <span @click="$emit('add-item')" class="add-brand-data__button"
      ><i class="icon-plus"></i
      ><span v-if="title" class="add-brand-data__text">{{ title }}</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'AddBrandDataButton',
  props: {
    title: String
  }
}
</script>

<style lang="scss" scoped>
.add-brand-data {
  text-align: center;
  &__button {
    transition: opacity 150ms ease-in-out;
    display: inline-block;
    margin: 0 auto;
    padding: 23px;
    cursor: pointer;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
    i {
      border-radius: 100%;
      display: inline-block;
      color: $white;
      width: 35px;
      height: 35px;
      line-height: 35px;
      background: $body-font-color;
      font-size: 11px;
      &::before {
        font-size: 15px;
      }
    }
  }
  &__text {
    position: relative;
    top: -2px;
    padding-left: 10px;
  }
}
</style>
