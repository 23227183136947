import { render, staticRenderFns } from "./PageSidebar.vue?vue&type=template&id=fb9b801e&scoped=true"
import script from "./PageSidebar.vue?vue&type=script&lang=js"
export * from "./PageSidebar.vue?vue&type=script&lang=js"
import style0 from "./PageSidebar.vue?vue&type=style&index=0&id=fb9b801e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb9b801e",
  null
  
)

export default component.exports