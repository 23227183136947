<template>
  <card-item>
    <h4 class="color--grey">Uitleg</h4>
    <p class="color--grey">Hier moet de uitleg van dit brand onderdeel ingeladen worden.</p>
  </card-item>
</template>

<script>
export default {
  name: 'tipModal'
}
</script>

<style lang="scss">
</style>
