const prefix = 'ABRND | '

export default [
  { path: '/', redirect: '/dashboard' },
  { path: '/merk/dashboard', redirect: '/dashboard' },
  { path: '/merk/welcome', redirect: '/welcome' },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "Login" */ '../views/auth/pages/Login'),
    meta: {
      title: prefix + 'Login'
    }
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () =>
      import(/* webpackChunkName: "SignUp" */ '../views/auth/pages/SignUp'),
    meta: {
      title: prefix + 'Sign Up'
    }
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: () =>
      import(/* webpackChunkName: "ForgotPassword" */ '../views/auth/pages/ForgotPassword'),
    meta: {
      title: prefix + 'Forgot password'
    }
  },
  {
    path: '/verify-password',
    name: 'verifyPassword',
    component: () =>
      import(/* webpackChunkName: "VerifyPassword" */ '../views/auth/pages/VerifyPassword'),
    meta: {
      title: prefix + 'Verify password'
    }
  },
  {
    path: '/complete-registration',
    name: 'completeRegistration',
    component: () =>
      import(/* webpackChunkName: "CompleteRegistration" */ '../views/auth/pages/CompleteRegistration'),
    meta: {
      title: prefix + 'Complete registration'
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ '../views/dashboard/pages/DashboardLayout'),
    meta: {
      title: prefix + 'Dashboard',
      requiresAuth: true
    }
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ '../views/welcome/pages/Welcome'),
    meta: {
      title: prefix + 'Welcome',
      requiresAuth: true
    }
  },
  {
    path: '/share/:pathName',
    name: 'Share',
    component: () =>
      import(/* webpackChunkName: "Shared" */ '../views/auth/pages/Shared'),
    meta: {
      title: prefix + 'Shared'
    }
  },
  { path: '/welcome/describe', redirect: '/merk/welcome/describe' },
  { path: '/dashboard/edit', redirect: '/merk/dashboard/edit' },
  {
    path: '/positioning',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "BrandLayout" */ '../views/brand/BrandLayout'),
    meta: {
      title: prefix + 'Merk',
      requiresAuth: true,
      breadCrumb: 'Merk'
    },
    children: [
      {
        path: '',
        name: 'BrandList',
        component: () =>
          import(/* webpackChunkName: "BrandList" */ '../views/brand/pages/BrandList'),
        meta: {
          title: prefix + 'Merk',
          requiresAuth: true,
          breadCrumb: 'Overzicht'
        }
      },
      {
        path: ':pathName',
        name: 'BrandDetail',
        component: () =>
          import(/* webpackChunkName: "BrandDetail" */ '../views/brand/pages/BrandDetail'),
        meta: {
          title: prefix + 'Merk',
          requiresAuth: true
        }
      },
      {
        path: ':pathName/edit',
        name: 'BrandDetailEdit',
        component: () =>
          import(/* webpackChunkName: "BrandDetailEdit" */ '../views/brand/pages/BrandDetailEdit'),
        meta: {
          title: prefix + 'Merk',
          requiresAuth: true
        }
      },
      {
        path: ':pathName/describe',
        name: 'BrandDescribe',
        component: () =>
          import(/* webpackChunkName: "BrandDescribe" */ '../views/brand/pages/BrandDescribe'),
        meta: {
          title: prefix + 'Merk',
          requiresAuth: true
        }
      },
      {
        path: ':pathName/describe/edit',
        name: 'BrandDescribeEdit',
        component: () =>
          import(/* webpackChunkName: "BrandDescribeEdit" */ '../views/brand/pages/BrandDescribeEdit'),
        meta: {
          title: prefix + 'Merk',
          requiresAuth: true,
          requiresGodfather: true
        }
      }
    ]
  },
  {
    path: '/guidelines',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "BrandGuidelines" */ '../views/brand/BrandLayout'),
    meta: {
      title: prefix + 'Merk',
      requiresAuth: true,
      breadCrumb: 'Merk'
    },
    children: [
      {
        path: '',
        name: 'BrandGuidelineList',
        component: () =>
          import(/* webpackChunkName: "BrandGuidelineList" */ '../views/brand/pages/BrandList'),
        meta: {
          title: prefix + 'Guidelines',
          requiresAuth: true,
          breadCrumb: 'Overzicht'
        }
      },
      {
        path: ':pathName',
        name: 'BrandGuidelineDetail',
        component: () =>
          import(/* webpackChunkName: "BrandGuidelineDetail" */ '../views/brand/pages/BrandDetail'),
        meta: {
          title: prefix + 'Merk',
          requiresAuth: true
        }
      },
      {
        path: ':pathName/edit',
        name: 'BrandGuidelineDetailEdit',
        component: () =>
          import(/* webpackChunkName: "BrandGuidelineDetailEdit" */ '../views/brand/pages/BrandDetailEdit'),
        meta: {
          title: prefix + 'Merk',
          requiresAuth: true
        }
      },
      {
        path: ':pathName/describe',
        name: 'BrandGuidelineDescribe',
        component: () =>
          import(/* webpackChunkName: "BrandGuidelineDescribe" */ '../views/brand/pages/BrandDescribe'),
        meta: {
          title: prefix + 'Merk',
          requiresAuth: true
        }
      },
      {
        path: ':pathName/describe/edit',
        name: 'BrandGuidelineDescribeEdit',
        component: () =>
          import(/* webpackChunkName: "BrandGuidelineDescribeEdit" */ '../views/brand/pages/BrandDescribeEdit'),
        meta: {
          title: prefix + 'Merk',
          requiresAuth: true,
          requiresGodfather: true
        }
      }
    ]
  },
  {
    path: '/brand-assets',
    component: () =>
      import(/* webpackChunkName: "AssetLayout" */ '../views/assets/AssetLayout'),
    meta: {
      title: prefix + 'Assets',
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'AssetList',
        component: () =>
          import(/* webpackChunkName: "AssetsIndex" */ '../views/assets/pages/AssetList'),
        meta: {
          title: prefix + 'Assets',
          requiresAuth: true
        }
      },
      {
        path: 'create',
        name: 'AssetCreate',
        component: () =>
          import(/* webpackChunkName: "AssetCreate" */ '../views/assets/pages/AssetCreate'),
        meta: {
          title: prefix + 'Create asset',
          requiresAuth: true
        }
      },
      {
        path: ':pathName',
        name: 'AssetDetail',
        component: () =>
          import(/* webpackChunkName: "AssetDetail" */ '../views/assets/pages/AssetDetail'),
        meta: {
          title: prefix + 'Assets',
          requiresAuth: true
        }
      },
      {
        path: ':pathName/edit',
        name: 'AssetEdit',
        component: () =>
          import(/* webpackChunkName: "AssetEdit" */ '../views/assets/pages/AssetEdit'),
        meta: {
          title: prefix + 'Assets',
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/planning',
    name: 'Calendar',
    component: () =>
      import(/* webpackChunkName: "Calendar" */ '../views/calendar/Calendar'),
    meta: {
      title: prefix + 'Planning',
      requiresAuth: true
    }
  },
  {
    path: '/interface-settings',
    component: () =>
      import(/* webpackChunkName: "InterfaceSettings" */ '../views/interface-settings/InterfaceSettings'),
    meta: {
      title: prefix + 'Interface settings',
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'InterfaceSettingsColors',
        component: () =>
          import(/* webpackChunkName: "InterfaceSettingsColors" */ '../views/interface-settings/pages/InterfaceSettingsColors'),
        meta: {
          requiresAuth: true,
          breadCrumb: 'Kleuren'
        }
      },
      {
        path: 'buttons',
        name: 'InterfaceSettingsButtons',
        component: () =>
          import(/* webpackChunkName: "InterfaceSettingsButtons" */ '../views/interface-settings/pages/InterfaceSettingsButtons'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'font',
        name: 'InterfaceSettingsFonts',
        component: () =>
          import(/* webpackChunkName: "InterfaceSettingsFonts" */ '../views/interface-settings/pages/InterfaceSettingsFonts'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'login-screen',
        name: 'InterfaceSettingsLoginScreen',
        component: () =>
          import(/* webpackChunkName: "InterfaceSettingsLoginScreen" */ '../views/interface-settings/pages/InterfaceSettingsLoginScreen'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'navigation',
        name: 'InterfaceSettingsNavigation',
        component: () =>
          import(/* webpackChunkName: "InterfaceSettingsNavigation" */ '../views/interface-settings/pages/InterfaceSettingsNavigation'),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/settings',
    component: () =>
      import(/* webpackChunkName: "SettingsLayout" */ '../views/settings/SettingsLayout'),
    meta: {
      title: prefix + 'Settings',
      requiresAuth: true
    },
    children: [
      {
        path: 'company',
        name: 'SettingsCompany',
        component: () =>
          import(/* webpackChunkName: "CompanyInfo" */ '../views/settings/pages/CompanyInfo'),
        meta: {
          requiresAuth: true,
          breadCrumb: 'Algemeen'
        }
      },
      {
        path: 'users',
        name: 'SettingsUsers',
        component: () =>
          import(/* webpackChunkName: "Users" */ '../views/settings/pages/Users'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'brand',
        name: 'SettingsBrand',
        component: () =>
          import(/* webpackChunkName: "BrandSettings" */ '../views/settings/pages/Brand'),
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/profile',
    component: () =>
      import(/* webpackChunkName: "ProfileLayout" */ '../views/profile/ProfileLayout'),
    meta: {
      title: prefix + 'Profile',
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'ProfileInfo',
        component: () =>
          import(/* webpackChunkName: "ProfileInfo" */ '../views/profile/pages/ProfileInfo'),
        meta: {
          requiresAuth: true,
          breadCrumb: 'Overzicht'
        }
      }
    ]
  },
  {
    path: '/updates',
    component: () =>
      import(/* webpackChunkName: "UpdateLayout" */ '../views/updates/UpdateLayout'),
    meta: {
      title: prefix + 'Updates',
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'UpdatesList',
        component: () =>
          import(/* webpackChunkName: "Updates" */ '../views/updates/pages/UpdatesList'),
        meta: {
          requiresAuth: true,
          breadCrumb: 'Overzicht'
        }
      },
      {
        path: ':pathName',
        name: 'UpdatesDetail',
        component: () =>
          import(/* webpackChunkName: "Updates" */ '../views/updates/pages/UpdatesDetail'),
        meta: {
          title: prefix + 'Updates',
          requiresAuth: true,
          menuIcon: false
        }
      }
    ]
  },
  {
    path: '/metrics',
    component: () =>
      import(/* webpackChunkName: "MetricsLayout" */ '../views/metrics/MetricsLayout'),
    meta: {
      title: prefix + 'Metrics',
      requiresAuth: true,
      requiresGodfather: true
    },
    children: [
      {
        path: '',
        name: 'MetricsOverview',
        component: () =>
          import(/* webpackChunkName: "Metrics" */ '../views/metrics/pages/MetricsOverview'),
        meta: {
          requiresAuth: true,
          requiresGodfather: true,
          breadCrumb: 'Overzicht'
        }
      },
      {
        path: 'user-performance',
        name: 'MetricsUserPerformance',
        component: () =>
          import(/* webpackChunkName: "Metrics" */ '../views/metrics/pages/MetricsUserPerformance'),
        meta: {
          requiresAuth: true,
          requiresGodfather: true
        }
      },
      {
        path: 'brand-usage',
        name: 'MetricsBrandUsage',
        component: () =>
          import(/* webpackChunkName: "Metrics" */ '../views/metrics/pages/MetricsBrandUsage'),
        meta: {
          requiresAuth: true,
          requiresGodfather: true
        }
      },
      {
        path: 'assets-usage',
        name: 'MetricsAssetsUsage',
        component: () =>
          import(/* webpackChunkName: "Metrics" */ '../views/metrics/pages/MetricsAssetsUsage'),
        meta: {
          requiresAuth: true,
          requiresGodfather: true
        }
      }
    ]
  }
]
