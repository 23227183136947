<template>
  <div class="profile-image__container">
    <div class="image" v-if="avatar" :style="userAvatar(avatar)"></div>
    <div class="image" v-else>
      <i class="icon-menu-profile"></i>
    </div>
    <p class="name">{{ name }}</p>
  </div>
</template>

<script>
export default {
  name: 'ProfileImage',
  props: {
    name: String,
    avatar: String
  },
  methods: {
    userAvatar (avatar) {
      return "background-image:url('" + avatar + "');"
    }
  }
}
</script>

<style lang="scss" scoped>
.profile-image__container {
  display: flex;
  align-items: center;
}
.name {
  padding-left: 10px;
}
.image {
  background-size: cover;
  background-position: center;
  border: 1px solid #95989a !important;
  border-radius: 100%;
  text-align: center;
  width: 30px;
  height: 30px;
  i {
    position: relative;
    top: 4px;
  }
}
</style>
