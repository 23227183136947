<template>
  <dropdown-item ref="dropdown" :isIcon="false" :align="'left'" :y="55" :x="103">
    <template slot="btn">
      <span class="button-branded button-branded--share">
        {{ $t('app.share') }} <i class="icon-icon-share-line"></i>
      </span>
    </template>
    <template slot="body">
      <div class="shareTags dropdown-share">
        <form id="shareForm">
          <card-item>
            <span
              class="copy-data dropdown-share__link"
              :data-copy-content="$t('app.copy')"
              @click="doCopy(currentUrl)"
              v-clipboard:copy="currentUrl"
            >{{ currentUrl }}</span
            >
            <input
              type="hidden"
              :value="currentUrl"
              name="shareLink"
              readonly
            />
          </card-item>
          <hr/>
          <card-item>
            <h5 class="medium">{{ $t('share.share_with_colleagues') }}</h5>
            <div class="spacer--10"></div>

            <ab-tags-field
              v-model="tags"
              :label="$t('share.share_placeholder')"
              :availableTags="autocompleteItems"
              :addOnlyFromAutocomplete="false"
              :hasUsers="true"
            />
            <div
              v-for="(tag, index) in tags"
              :key="index"
              class="tags-input-tag"
            >
              <div
                class="grid-x grid-margin-x align-middle tags-input-tag__item"
              >
                <div class="cell shrink">
                  <div
                    class="share-avatar"
                    v-if="tag.avatar"
                    :style="{
                      'background-image': 'url(' + tag.avatar.url + ')'
                    }"
                  ></div>
                  <div class="share-avatar" v-else>
                    <i class="icon-menu-profile"></i>
                  </div>
                </div>
                <div class="cell auto ml-0" v-if="tag.name">
                  {{ tag.name }} {{ tag.surname }}
                </div>
                <div class="cell auto" v-else>
                  {{ tag.text }}
                </div>
                <div class="cell shrink">
                  <i
                    class="icon-icon-trash"
                    data-deleteshare
                    @click.prevent="deleteObject(index)"
                  ></i>
                </div>
              </div>
            </div>
            <div v-if="hasExternalRecipients">
              <div class="spacer--20"></div>
              <ab-checkbox v-model="sharePublicLink" name="public-link" :label="$t('share.public_link')"/>
            </div>
          </card-item>
          <hr/>
          <card-item>
            <h5 class="medium">{{ $t('share.add_a_message') }}</h5>
            <div class="spacer--10"></div>
            <div class="event-item__field">
              <textarea
                v-model="shareMessage"
                rows="1"
                name="shareMessage"
              ></textarea>
              <label>{{ $t('app.message') }}</label>
            </div>
            <div class="spacer--20"></div>
            <div class="text-right pb-3">
              <button
                v-if="shareMessage.length >= 0"
                type="submit"
                class="color--primary"
                @click.prevent="submitShare"
              >
                {{ $t('app.send') }}
              </button>
              <span v-else class="disabled--submit">{{ $t('app.send') }}</span>
            </div>
          </card-item>
        </form>
        <ab-loader v-if="showLoader"></ab-loader>
      </div>
    </template>
  </dropdown-item>
</template>

<script>
import backend from '@/backend'

export default {
  name: 'ShareDropdown',
  props: {
    subjectType: String,
    subjectId: Number
  },
  data () {
    return {
      showLoader: false,
      tag: '',
      tags: [],
      autocompleteItems: [],
      validation: [
        {
          classes: 'is-invalid',
          rule: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          disableAdd: true
        }
      ],
      shareMessage: '',
      sharePublicLink: true,
      userData: false,
      currentUrl: ''
    }
  },
  mounted () {
    // Listen to the dropdown-open event and get userlist when triggered
    this.$root.$on('bp-dropdown:open', () => this.dropdownOpened())
  },
  methods: {
    deleteObject: function (index) {
      this.$delete(this.tags, index)
    },
    submitShare: function () {
      this.showLoader = true
      backend
        .post(`${process.env.VUE_APP_URL}/mail`, {
          sendTo: this.emailList,
          pageTitle: this.pageTitle,
          shareMessage: this.shareMessage,
          shareLink: this.currentUrl,
          subjectType: this.subjectType,
          subjectId: this.subjectId,
          sharePublicLink: this.sharePublicLink
        })
        .then(() => {
          this.tags = []
          this.shareMessage = ''
          this.$root.$emit('bp-dropdown:hide')
          this.$notify({
            group: 'custom-template',
            title: false,
            position: 'bottom left',
            text: this.$t('share.message_send')
          })
          this.showLoader = false
        })
        .catch(() => {
          this.showLoader = false
          this.$notify({
            group: 'custom-template-error',
            title: false,
            position: 'bottom left',
            text: this.$t('share.message_error')
          })
        })
    },
    dropdownOpened: function () {
      this.setCurrentUrl()
      if (!this.userData) {
        backend.get(`${process.env.VUE_APP_URL}/me`).then(response => {
          this.userData = response.data
        })
      }
      if (!this.autocompleteItems.length) {
        backend.get(`${process.env.VUE_APP_URL}/users`).then(response => {
          const users = []
          for (const key in response.data) {
            const user = response.data[key]
            users.push({
              text: user.email,
              name: user.name,
              surname: user.surname,
              avatar: user.avatar
            })
          }
          this.autocompleteItems = users
        })
      }
    },
    doCopy: function (value) {
      this.$copyText(value)
      this.$notify({
        group: 'custom-template',
        title: false,
        position: 'bottom left',
        text: this.$t('share.copy_url', {
          value: value
        })
      })
    },
    setCurrentUrl: function () {
      this.currentUrl =
        window.location.protocol +
        '//' +
        window.location.host +
        this.$router.currentRoute.fullPath
    }
  },
  computed: {
    hasExternalRecipients () {
      for (const key in this.tags) {
        if (!this.tags[key].name) {
          return true
        }
      }
      return false
    },
    emailList () {
      return this.tags.map(b => {
        return b.text
      })
    },
    abrnd_name () {
      return backend.state.brand
    },
    pageTitle () {
      return document.title
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/init/variables/variables";

.tags-input-tag {
  font-size: 14px;
  font-weight: 500;

  &__item {
    margin-top: 30px;
  }

  i {
    color: $light-gray;
    cursor: pointer;

    &:hover {
      i {
        color: $white;
      }
    }
  }
}

.shareTags {
  .vue-tags-input .ti-input .ti-tag {
    display: none !important;
  }
}
</style>
