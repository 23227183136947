<template>
  <span @click="$emit('cancel')" class="ab-button--white ab-button--cancel"
    >{{ $t("app.cancel") }} <i class="icon-close"></i
  ></span>
</template>

<script>
export default {
  name: 'CancelButton'
}
</script>

<style scoped></style>
