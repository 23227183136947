<template>
  <div
    v-if="hasUsers"
    class="ab-tags"
    :class="[
      error ? 'has-error' : '',
      error && errorMessage ? 'has-message' : '',
      customClass ? customClass : ''
    ]"
  >
    <vue-tags-input
      v-model="tagInput"
      :tags="value"
      :add-on-key="[13, ',']"
      :autocomplete-items="filteredTags"
      placeholder=""
      :max-tags="maxTags"
      :add-only-from-autocomplete="addOnlyFromAutocomplete"
      :validation="validation"
      :delete-on-backspace="false"
      @tags-changed="tagsChanged"
    >
      <div
        slot="autocomplete-item"
        slot-scope="props"
        @click="props.performAdd(props.item)"
      >
        <div class="grid-x grid-margin-x align-middle">
          <div class="cell shrink">
            <div
              class="share-avatar"
              v-if="props.item.avatar"
              :style="userAvatar(props.item.avatar.url)"
            ></div>
            <div class="share-avatar" v-else>
              <i class="icon-menu-profile"></i>
            </div>
          </div>
          <div class="cell auto ml-0">
            {{ props.item.name }} {{ props.item.surname }}
          </div>
        </div>
      </div>
    </vue-tags-input>
    <label>{{ label }}</label>
    <span v-if="error && errorMessage" class="ab-tags__error-message">{{
        errorMessage
      }}</span>
  </div>
  <div
    v-else
    class="ab-tags"
    :class="[
      error ? 'has-error' : '',
      error && errorMessage ? 'has-message' : '',
      customClass ? customClass : ''
    ]"
  >
    <vue-tags-input
      v-model="tagInput"
      :tags="value"
      :add-on-key="[13, ',']"
      :autocomplete-items="filteredTags"
      placeholder=""
      :max-tags="maxTags"
      :add-only-from-autocomplete="addOnlyFromAutocomplete"
      :autocomplete-always-open="showAutoComplete"
      @tags-changed="tagsChanged"
      @focus="onFocus"
      @blur="onBlur"
    />
    <label>{{ label }}</label>
    <span v-if="error && errorMessage" class="ab-tags__error-message">{{
        errorMessage
      }}</span>
  </div>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input'

export default {
  name: 'AbTags',
  data () {
    return {
      tagInput: '',
      showAutoComplete: false,
      cancelUnFocus: false,
      validation: [
        {
          classes: 'is-invalid',
          rule: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          disableAdd: true
        }
      ]
    }
  },
  props: {
    label: String,
    value: [String, Array],
    tags: Array,
    availableTags: Array,
    placeholder: String,
    maxTags: Number,
    addOnlyFromAutocomplete: Boolean,
    alwaysShowAutocomplete: Boolean,
    error: Boolean,
    errorMessage: String,
    customClass: String,
    hasUsers: Boolean
  },
  computed: {
    filteredTags () {
      if (this.availableTags.length === 0) return
      return this.availableTags.filter(i => {
        return i.text.toLowerCase().indexOf(this.tagInput.toLowerCase()) === 0
      })
    }
  },
  methods: {
    onFocus: function () {
      this.showAutoComplete = this.addOnlyFromAutocomplete || this.alwaysShowAutocomplete
    },
    onBlur: function () {
      // this needs to be in a timeout because otherwise it will cancel the tag-added event.
      setTimeout(() => {
        if (!this.cancelUnFocus) {
          this.showAutoComplete = false
        }
        this.cancelUnFocus = false
        if (this.addOnlyFromAutocomplete) {
          this.tagInput = ''
        }
      }, 200)
    },
    tagsChanged: function (newTags) {
      this.$emit('input', newTags)
      this.$emit('tags-changed')
      this.cancelUnFocus = true
    },
    userAvatar (avatar) {
      return 'background-image:url(\'' + avatar + '\');'
    }
  },
  components: { VueTagsInput }
}
</script>

<style scoped lang="scss">
.ab-tags {
  overflow: visible;
  position: relative;
  min-height: 50px;
  color: $dark-gray;
  padding: 0;
  display: flex;
  word-break: break-word;

  ul {
    li {
      padding-left: 0;
      margin-left: 0 !important;
      margin-right: 0.25em !important;

      &::before {
        display: none;
      }

      .tag {
        margin: 0;
      }
    }
  }

  label {
    position: absolute;
    left: 16px;
    font-size: 12px;
    color: $light-gray;
    top: -10px;
    background: $white;
    padding: 0 5px;
    height: 16px;
  }

  &__error-message {
    position: absolute;
    bottom: -15px;
    font-size: 10px;
    color: $error;
    left: 20px;
    line-height: 10px;
  }

  &.has-error {
    &::after {
      position: absolute;
      font-family: $abrnd;
      color: $error;
      content: $icon-input-error;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
    }

    label {
      color: $error;
    }
  }
}

.share-avatar {
  background-size: cover;
  background-position: center;
  border: 1px solid #95989a !important;
  border-radius: 100%;
  text-align: center;
  display: block;
  width: 30px;
  height: 30px;

  i {
    position: relative;
    top: 7px;
  }
}
</style>

<style lang="scss">
.ab-tags {
  &--category {
    .ti-input {
      .ti-tag {
        + .ti-new-tag-input-wrapper {
          display: none !important;
        }
      }
    }
  }

  .vue-tags-input {
    transition: border 150ms ease-in-out;
    max-width: 100% !important;
    border: 2px solid $ui-line-color;
    width: 100% !important;
    background: transparent !important;
    border-radius: $form-radius;
    //height: 52px;
    &.ti-focus {
      border-color: $gray;
    }

    .ti-input {
      border: none !important;
      padding: 0 !important;

      .ti-tag {
        border-radius: $global-radius;
        background: $cancel !important;
        color: $light-gray !important;
        border: 0 !important;
        display: inline-block !important;
        padding: 0 !important;
        position: relative;
        height: 29px !important;

        &.ti-deletion-mark {
          .ti-tag-center {
            background: $error !important;
            color: $white !important;

            &::before {
              background: $error !important;
            }
          }

          .ti-actions {
            i {
              color: $white !important;
            }
          }

          &:hover {
            color: $error !important;
          }
        }

        .ti-actions {
          position: absolute;
          right: 8px;
          top: calc(50% - 1px);
          transform: translateY(-50%);
          z-index: 3;
          font-weight: 800;
          font-size: 18px;
        }

        .ti-tag-center {
          background: transparent;
          padding: 7px 30px 5px 11px;
          color: $gray;
          border-radius: 5px;
          position: relative;
          font-weight: 400;
          z-index: 1;
        }
      }

      li {
        color: $error !important;
        font-size: 14px !important;

        input {
          height: 31px !important;
          color: $dark-gray;
          font-size: 14px !important;
        }

        margin: 9px 0 0 9px !important;
        background: transparent !important;

        &::before {
          display: none;
        }
      }
    }
  }
}

.asset-search {
  .vue-tags-input {
    transition: border 150ms ease-in-out;
    max-width: 100% !important;
    width: 100% !important;
    background: transparent !important;
    border-radius: $form-radius;

    &.ti-focus {
      border-color: $gray;
    }

    .ti-input {
      border: none !important;
      padding: 0 !important;

      .ti-tag {
        border-radius: 0 !important;
        border: 0 !important;
        display: inline-block !important;
        padding: 0 !important;
        position: relative;
        height: 29px !important;

        i.icon-close {
          position: absolute;
          right: 17px;
          top: 50%;
          transform: translateY(-50%);
          z-index: 3;
          font-weight: 800;
          font-size: 12px;
        }

        &.ti-deletion-mark {
          .tag-type {
            &--tag,
            &--category,
            &--other {
              background: $delete !important;
              border-color: $delete !important;
              color: $white !important;

              &::before {
                background: $delete !important;
              }
            }
          }
        }

        .ti-actions {
          opacity: 0;
          visibility: hidden;
          position: absolute;
          right: 8px;
          top: 50%;
          transform: translateY(-50%);
          z-index: 3;
          font-weight: 800;
          font-size: 22px;
        }

        .tag-type {
          &--tag {
            $square-size: 28px;
            background: var(--secondary-color);
            border: solid 1px var(--secondary-color);
            padding: 11px 40px 10px 14px;
            border-radius: 3px;
            position: relative;
            font-weight: 600;
            color: $white;
            z-index: 1;

            &:hover {
              color: rgba($delete, 1);
              border: solid, 1px;
              border-color: rgba($delete, 1);
              background: rgba($delete, 0.05);
            }
          }

          &--category {
            $square-size: 28px;
            background: var(--primary-color);
            border: solid 1px var(--primary-color);
            padding: 11px 40px 10px 14px;
            color: $white;
            border-radius: 3px;
            position: relative;
            font-weight: 600;
            z-index: 1;

            &:hover {
              color: rgba($delete, 1);
              border: solid, 1px;
              border-color: rgba($delete, 1);
              background: rgba($delete, 0.05);
            }
          }

          &--other {
            border: 1px solid var(--primary-color);
            background: $white;
            padding: 11px 39px 10px 14px;
            color: $gray;
            border-radius: 3px;
            position: relative;
            font-weight: 600;
            z-index: 1;

            &:hover {
              color: rgba($delete, 1);
              border: solid, 1px;
              border-color: rgba($delete, 1);
              background: rgba($delete, 0.05);
            }
          }

        }
      }

      li {
        color: $dark-gray !important;
        font-size: 14px !important;

        input {
          height: 42px !important;
          color: $dark-gray;
          font-size: 14px !important;
        }

        margin: 5px 5px 0px 0 !important;
        background: transparent !important;

        &::before {
          display: none;
        }
      }
    }
  }
}

.vue-tags-input {
  &.ti-focus {
    li {
      color: $gray;
    }
  }
}

.ti-autocomplete {
  border-radius: 5px;
  box-shadow: 0 3px 3px rgba(#000000, 0.1);
  background: $white;
  padding: 10px !important;
  border: none;

  li {
    &::before {
      display: none;
    }

    display: block;
    font-size: 14px;
    padding: 5px 10px !important;
    border-radius: 5px;
    color: $gray;
    cursor: pointer;
    position: relative;

    &:hover {
      color: $gray !important;
    }

    div {
      padding: 0;
    }

    &.ti-selected-item {
      background-color: $light-gray-bg;
    }
  }
}
</style>
