var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ab-input",class:[
    _vm.icon ? 'ab-input--icon ab-input--icon-readonly' : '',
    _vm.error ? 'has-error' : '',
    !_vm.error ? 'is-valid' : '',
    _vm.inputPrefix ? 'input-has-prefix' : '',
    _vm.error && _vm.errorMessage ? 'has-message' : ''
  ],attrs:{"data-input-prefix":_vm.inputPrefix}},[(_vm.icon)?_c('i',{class:_vm.icon}):_vm._e(),(!_vm.error && !_vm.errorMessage)?_c('span',{staticClass:"ab-input__error-message"},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e(),(_vm.readonly)?_c('span',{staticClass:"ab-input__readonly"},[_vm._v(_vm._s(_vm.value))]):((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputVal),expression:"inputVal"}],attrs:{"max":_vm.max,"required":_vm.required,"autocomplete":_vm.autocomplete,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.inputVal)?_vm._i(_vm.inputVal,null)>-1:(_vm.inputVal)},on:{"change":function($event){var $$a=_vm.inputVal,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.inputVal=$$a.concat([$$v]))}else{$$i>-1&&(_vm.inputVal=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.inputVal=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputVal),expression:"inputVal"}],attrs:{"max":_vm.max,"required":_vm.required,"autocomplete":_vm.autocomplete,"type":"radio"},domProps:{"checked":_vm._q(_vm.inputVal,null)},on:{"change":function($event){_vm.inputVal=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputVal),expression:"inputVal"}],attrs:{"max":_vm.max,"required":_vm.required,"autocomplete":_vm.autocomplete,"type":_vm.type},domProps:{"value":(_vm.inputVal)},on:{"input":function($event){if($event.target.composing)return;_vm.inputVal=$event.target.value}}}),(_vm.label)?_c('label',[_vm._v(_vm._s(_vm.label)+" "),(_vm.required)?_c('span',[_vm._v("*")]):_vm._e()]):_vm._e(),(_vm.error && _vm.errorMessage)?_c('span',{staticClass:"ab-input__error-message"},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }