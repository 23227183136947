<template>
  <card-item :title="title" :icon="icon">
    <ul class="user-list">
      <li class="user-list__item">
        <div class="grid-x align-middle">
          <div
            class="cell"
            v-for="(header, index) in headers"
            :key="'key-' + index"
            :class="[index === 0 ? 'auto' : 'shrink']"
          >
            <span class="user-list__header">{{ header }}</span>
          </div>
        </div>
      </li>
      <transition-group name="fade" mode="out-in">
        <li
          class="user-list__item"
          v-for="(item, index) in items"
          :key="'key-' + index"
        >
          <div class="grid-x align-middle">
            <div
              class="cell"
              v-for="(propt, index, key) in item"
              :key="index"
              :class="[key === 0 ? 'auto' : 'shrink']"
            >
              <div
                :class="[key === 0 ? '' : 'user-list__option']"
                v-if="typeof propt === 'object'"
              >
                <div class="grid-x align-middle">
                  <div class="cell shrink">
                    <span
                      v-if="propt.avatar"
                      class="user-avatar"
                      :style="userAvatar(propt.avatar)"
                    ></span>
                    <span v-else class="user-avatar user-avatar--empty"></span>
                  </div>
                  <div class="cell auto">
                    <span class="user-list__name">{{ propt.name }}</span>
                  </div>
                </div>
              </div>
              <div
                :class="[key === 0 ? 'user-list__name' : 'user-list__option']"
                v-else
              >
                {{ propt }}
              </div>
            </div>
          </div>
        </li>
      </transition-group>
    </ul>
    <div class="user-list__more" v-if="readmore">
      <router-button
        :routeName="readmoreRoute"
        :text="$t('app.view_all')"
      ></router-button>
    </div>
  </card-item>
</template>

<script>
export default {
  name: 'DataTable',
  props: {
    icon: String,
    title: String,
    headers: Array,
    items: Array,
    readmore: Boolean,
    readmoreRoute: String
  },
  methods: {
    userAvatar (avatar) {
      return 'background-image:url(\'' + avatar + '\');'
    }
  }
}
</script>

<style lang="scss" scoped>
.user-list {
  padding: 0px;

  > li {
    position: relative;

    &:hover {
      background: transparent;
    }
  }

  .user-avatar {
    position: relative;
    top: 4px;
    background-position: center;
    background-size: cover;
    margin-right: 10px;

    &--empty {
      top: 0;
    }
  }

  &__header,
  &__option {
    width: 150px;
  }

  &__item {
    padding: 10px 15px;
    margin: 0px -15px 0px -15px;

    &::before {
      display: none;
    }

    &:hover {
      background: $light-gray-bg;
    }
  }

  > span {
    .user-list__item {
      height: 58px;

      .grid-x {
        height: 100%;
      }
    }
  }

  &__header {
    display: block;
    color: #454545;
    font-size: 14px;
    font-weight: 400;
  }

  &__name,
  &__option {
    display: block;
    color: #454545;
    font-size: 14;
    line-height: 1.2;
    font-weight: 500;
  }

  &__status {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 100%;
    margin-right: 5px;
    position: relative;
    top: 1px;

    &.active {
      background-color: $success-color;
    }

    &.inactive {
      background-color: $error;
    }
  }

  &__more {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: right;
    padding: 10px 30px 0px;

    a {
      text-decoration: none;
    }
  }
}
</style>
