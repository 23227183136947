<template>
  <span
    :class="[
      customClass ? customClass : 'editor-item__delete editor-item__button'
    ]"
  >
    <span @click="show(id)"><i class="icon-icon-trash"></i></span>
  </span>
</template>

<script>
export default {
  name: 'deleteCard',
  props: {
    customClass: String
  },
  data () {
    return {
      id: null
    }
  },
  mounted () {
    this.id = this._uid
  },
  methods: {
    show (id) {
      this.$modal.show('dialog', {
        title: '',
        text: this.$t('app.remove_item'),
        buttons: [
          {
            title: this.$t('app.cancel'),
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: this.$t('app.remove_item'),
            default: true,
            handler: () => {
              this.$emit('confirmDelete')
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
hr {
  margin: 0;
}
</style>
